import AutorenewIcon from '@mui/icons-material/Autorenew';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowDownSharpIcon from '@mui/icons-material/KeyboardArrowDownSharp';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {
  Box,
  Button,
  Checkbox,
  ClickAwayListener,
  Divider,
  Fade,
  FormControlLabel,
  Grid,
  Link,
  MenuItem,
  Modal,
  Popover,
  Radio,
  // RadioGroup,
  Select,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import ButtonGroup from '@mui/material/ButtonGroup';
import { grey } from '@mui/material/colors';
// import FormLabel from '@mui/material/FormLabel';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Popper, { PopperPlacementType } from '@mui/material/Popper';
import RadioGroup from '@mui/material/RadioGroup';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import arrowcolumns from 'assets/images/arrowcolumns.svg';
import CustomButton from 'components/CustomButton/customButton';
import saveAs from 'file-saver';
import JSZip from 'jszip';
import JSZipUtils from 'jszip-utils';
import moment from 'moment';
import React, { useEffect } from 'react';
import LoadingOverlay from 'react-loading-overlay';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import {
  addNewData,
  getAllListData,
  updateData,
} from 'services/CommonServices';
import { useAppSelector } from 'store/hooks';
import { UPDATE_CONSULTANT_FILTER } from 'store/types/UserTypes';
import { alertOptions, RestrictionProd } from 'utils/Config';
import { MASTER_ENDPOINT } from 'utils/Constant';

import ListTableRow from './ListTableRow';
import useStyles from './style';

const style = {
  bgcolor: 'background.paper',
  borderRadius: '10px',
  boxShadow: 24,
  left: '50%',
  p: 4,
  position: 'absolute' as const,
  top: '50%',
  transform: 'translate(-50%, -50%)',
  width: 486,
};

const staticParam = {
  branch: '',
  collectionFrequency: '',
  hours: '',
  lob: '',
  order: 'asc',
  searchWord: '',
  skip: 0,
  sortby: 'jobWcCode',
  take: 100,
  timesheets: '',
};

export const Consultants: React.FC<any> = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const zip: any = new JSZip();

  // const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(100);
  const [list, setList] = React.useState<any>([]);
  const [payPeriodList, setPayPeriodList] = React.useState<any>([]);
  // const [periodIndex, setPeriodIndex] = React.useState(0);
  const [requestList, setRequestList] = React.useState([]);
  const [total, setTotal] = React.useState(0);
  const [hourTotal, setHourTotal] = React.useState(0);
  const [timesheetTotal, setTimesheetTotal] = React.useState(0);
  const [radio, setRadio] = React.useState<string>('both');
  const [modalOpen, setModalOpen] = React.useState<boolean>(false);
  const [message, setMessage] = React.useState<string>(
    'You have not submitted your timesheets/hours for a month. This is a first written warning that if you do not submit your timesheet/hours by X day, your employment may be terminated for violation of company policy.',
  );
  const [loader, setLoader] = React.useState<boolean>(false);
  const { companyStatus, searchText, consultantFilter }: any = useAppSelector(
    (store: any) => store.userLogin,
  );

  useEffect(() => {
    if (payPeriodList.length && consultantFilter.periodIndex !== -1) {
      getPeriodData(
        consultantFilter,
        payPeriodList[consultantFilter.periodIndex],
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyStatus, payPeriodList, consultantFilter]);

  useEffect(() => {
    dispatch({
      payload: {
        ...consultantFilter,
        searchWord: searchText,
      },
      type: UPDATE_CONSULTANT_FILTER,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);

  const getData = () => {
    getAllListData(MASTER_ENDPOINT.Payperiods)
      .then((resp: any) => {
        const filterData = resp?.data;
        // const filterData = resp?.data.find(
        //   (item: any) => item.year === parseInt(moment().format('YYYY')),
        // );
        if (filterData) {
          const dateArray = [];
          filterData.map((row: any) => {
            row.payPeriodQuarters.map((item: any) => {
              item.payPeriods.map((item1: any) => {
                dateArray.push(item1);
              });
            });
          });
          if (dateArray.length) {
            const dateAObject = dateArray.sort(function (a, b) {
              const c: any = new Date(a.startDate);
              const d: any = new Date(b.startDate);
              return c - d;
            });
            setPayPeriodList(dateAObject);
            if (dateAObject.length) {
              const fIndx = dateAObject.findIndex((item: any) =>
                moment(moment().format('YYYY-MM-DD')).isBetween(
                  moment(item.startDate),
                  moment(item.endDate),
                  undefined,
                  '[]',
                ),
              );
              if (fIndx !== -1) {
                if (!consultantFilter.periodIndex) {
                  dispatch({
                    payload: {
                      ...consultantFilter,
                      periodIndex: fIndx,
                    },
                    type: UPDATE_CONSULTANT_FILTER,
                  });
                }
                // setPeriodIndex(fIndx);
              } else {
                const findLastIndex = (arr, fn) =>
                  (arr
                    .map((val, i) => [i, val])
                    .filter(([i, val]) => fn(val, i, arr))
                    .pop() || [-1])[0];
                const fIndx1 = findLastIndex(dateAObject, (item: any) =>
                  moment(item.endDate).isBefore(
                    moment(moment().format('YYYY-MM-DD')),
                  ),
                );
                if (fIndx1 !== -1) {
                  // setPeriodIndex(fIndx1);
                  if (!consultantFilter.periodIndex) {
                    dispatch({
                      payload: {
                        ...consultantFilter,
                        periodIndex: fIndx1,
                      },
                      type: UPDATE_CONSULTANT_FILTER,
                    });
                  }
                } else {
                  // setPeriodIndex(0);
                  if (!consultantFilter.periodIndex) {
                    dispatch({
                      payload: {
                        ...consultantFilter,
                        periodIndex: 0,
                      },
                      type: UPDATE_CONSULTANT_FILTER,
                    });
                  }
                }
              }
            }
          }
        }
      })
      .catch((err: any) => {});
  };

  const [branchList, setBranchList] = React.useState<any>([]);
  const [branchDownload, setBranchDownload] = React.useState<any>('');
  // const [branch, setBranch] = React.useState<any>('');
  const getBrach = () => {
    getAllListData(MASTER_ENDPOINT.Consultants + '/branches')
      .then((resp: any) => {
        setBranchList(resp?.data);
      })
      .catch((err: any) => {});
  };

  const [lobList, setLobList] = React.useState<any>([]);
  const getLob = () => {
    getAllListData(MASTER_ENDPOINT.Consultants + '/lob')
      .then((resp: any) => {
        setLobList(resp?.data);
      })
      .catch((err: any) => {});
  };

  const filterChange = (type: string, val: string) => {
    if (type === 'hours') {
      dispatch({
        payload: {
          ...consultantFilter,
          hours: val,
        },
        type: UPDATE_CONSULTANT_FILTER,
      });
    } else if (type === 'timesheets') {
      dispatch({
        payload: {
          ...consultantFilter,
          timesheets: val,
        },
        type: UPDATE_CONSULTANT_FILTER,
      });
    } else if (type === 'branch') {
      dispatch({
        payload: {
          ...consultantFilter,
          branch: val,
        },
        type: UPDATE_CONSULTANT_FILTER,
      });
    } else if (type === 'lob') {
      dispatch({
        payload: {
          ...consultantFilter,
          lob: val,
        },
        type: UPDATE_CONSULTANT_FILTER,
      });
    } else if (type === 'collectionFrequency') {
      dispatch({
        payload: {
          ...consultantFilter,
          collectionFrequency: val,
        },
        type: UPDATE_CONSULTANT_FILTER,
      });
    }
    setOpen3(false);
  };

  const [userIds, setUserids] = React.useState([]);
  const getPeriodData = (paramObject: any, dateObjec?: any) => {
    setLoader(true);
    setList([]);
    setTotal(0);
    setHourTotal(0);
    setTimesheetTotal(0);
    let url =
      MASTER_ENDPOINT.Consultants +
      '?order=' +
      paramObject.order +
      '&sortby=' +
      paramObject.sortby +
      '&skip=' +
      paramObject.skip +
      '&take=' +
      paramObject.take +
      '&periodFrom=' +
      dateObjec.startDate +
      '&periodTo=' +
      dateObjec.endDate +
      '&search=' +
      paramObject.searchWord;
    if (companyStatus !== 'all') {
      url += `&organization=${String(companyStatus)}`;
    }
    if (paramObject.hours) {
      url += '&hours=' + paramObject.hours;
    }
    if (paramObject.branch) {
      url += '&branch=' + paramObject.branch;
    }
    if (paramObject.lob) {
      url += '&lob=' + paramObject.lob;
    }
    if (paramObject.collectionFrequency) {
      url += '&collectionFrequency=' + paramObject.collectionFrequency;
    }
    if (paramObject.timesheets) {
      url += '&timesheets=' + paramObject.timesheets;
    }

    getAllListData(url)
      .then((resp: any) => {
        setUserids(resp?.userIds);
        setList(resp?.data);
        setTotal(resp?.total?.total ? parseInt(resp?.total?.total) : 0);
        setHourTotal(resp?.headerTotal[0]?.hours);
        setTimesheetTotal(resp?.headerTotal[0]?.timesheet);
        setLoader(false);
      })
      .catch((err: any) => {
        setLoader(false);
      });
  };

  useEffect(() => {
    getData();
    getBrach();
    getLob();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setAllEvent = (checked: boolean) => {
    if (checked) {
      const userList = [];
      list.filter((row: any) => {
        if (row.userId) {
          userList.push(row.userId);
        }
      });
      setRequestList(userList);
    } else {
      setRequestList([]);
    }
  };

  const checkEvent = (check, userDataId) => {
    if (userDataId) {
      if (check) {
        requestList.push(userDataId);
        setRequestList([...requestList]);
      } else {
        const filterList = requestList.filter(
          (item: any) => item !== userDataId,
        );
        setRequestList(filterList);
      }
    }
  };

  const setParamData = (str: string) => {
    if (payPeriodList.length) {
      if (consultantFilter.sortby === str) {
        dispatch({
          payload: {
            ...consultantFilter,
            order: consultantFilter.order === 'asc' ? 'desc' : 'asc',
            skip: 0,
            sortby: consultantFilter.sortby,
            take: 100,
          },
          type: UPDATE_CONSULTANT_FILTER,
        });
      } else {
        dispatch({
          payload: {
            ...consultantFilter,
            order: 'asc',
            skip: 0,
            sortby: str,
            take: 100,
          },
          type: UPDATE_CONSULTANT_FILTER,
        });
      }
    }
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    // setPage(newPage);
    dispatch({
      payload: {
        ...consultantFilter,
        skip: newPage * 100,
      },
      type: UPDATE_CONSULTANT_FILTER,
    });
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 11));
    // setPage(0);
    dispatch({
      payload: staticParam,
      type: UPDATE_CONSULTANT_FILTER,
    });
  };

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null,
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setRadio('both');
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const [anchorEl2, setAnchorEl2] = React.useState<HTMLButtonElement | null>(
    null,
  );

  const handleClick2 = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl2(event.currentTarget);
  };

  const handleClose2 = () => {
    setAnchorEl2(null);
  };

  const open2 = Boolean(anchorEl2);
  const id2 = open2 ? 'simple-popover' : undefined;

  const [anchorEl3, setAnchorEl3] = React.useState<HTMLButtonElement | null>(
    null,
  );
  const [open3, setOpen3] = React.useState(false);
  const [placement3, setPlacement3] = React.useState<PopperPlacementType>();
  const [filterType, setFilterType] = React.useState('');

  const handleClick3 =
    (newPlacement: PopperPlacementType, type: string) =>
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl3(event.currentTarget);
      setOpen3((prev) => placement3 !== newPlacement || !prev);
      setPlacement3(newPlacement);
      setFilterType(type);
    };

  const sendRequest = () => {
    // if (radio !== 'timesheets' && !requestList.length) {
    //   toast.error('Please select atleast one consultant...', alertOptions);
    //   return;
    // } else {
    //   if (radio === 'timesheets' && !branch) {
    //     toast.error('Please select branch...', alertOptions);
    //     return;
    //   }
    //   setModalOpen(true);
    // }
    if (!requestList.length) {
      toast.error('Please select atleast one consultant...', alertOptions);
      return;
    }
    setModalOpen(true);
  };

  const [loading, setLoading] = React.useState<boolean>(false);
  const submitRequest = () => {
    setLoading(true);
    const bodyObject = {
      bodyContent: message,
      // branches: radio === 'timesheets' ? [branch] : [],
      branches: [],
      type: radio,
      // userId: radio !== 'timesheets' ? requestList : [],
      userId: requestList,
    };
    addNewData(
      bodyObject,
      MASTER_ENDPOINT.Consultants + '/send-request',
      {},
      true,
    )
      .then((resp: any) => {
        setLoading(false);
        setModalOpen(false);
        setRequestList([]);
        getPeriodData(
          consultantFilter,
          payPeriodList[consultantFilter.periodIndex],
        );
        // setBranch('');
      })
      .catch((err: any) => {
        setLoading(false);
      });
  };

  const downloadFiles = async () => {
    setLoader(true);
    await getAllListData(
      MASTER_ENDPOINT.Consultants +
        '?periodFrom=' +
        payPeriodList[consultantFilter.periodIndex].startDate +
        '&periodTo=' +
        payPeriodList[consultantFilter.periodIndex].endDate +
        '&organization=' +
        companyStatus +
        '&branch=' +
        branchDownload,
    )
      .then(async (resp: any) => {
        const downLoadListIds: any = [];
        const downLoadList = resp?.data;
        for (const [i, relativePath] of downLoadList.entries()) {
          if (
            downLoadList.length - 1 === i &&
            relativePath.timesheetFiles === null
          ) {
            zip.generateAsync({ type: 'blob' }).then(function (content) {
              saveAs(content, 'riseIT.zip');
            });
          }
          if (
            relativePath.timesheetFiles &&
            relativePath.timesheetFiles.length
          ) {
            const firstName = relativePath.firstName.replace(' ', '-');
            const lastName = relativePath.lastName.replace(' ', '-');
            const ultraStaffDbName = relativePath.ultraStaffDbName.replace(
              ' ',
              '-',
            );
            const periodToDate = moment(relativePath.periodTo).format('DD');
            const periodToMonth = moment(relativePath.periodTo).format('MM');
            const periodToMonthName = moment(relativePath.periodTo).format(
              'MMMM',
            );
            const periodToYear = moment(relativePath.periodTo).format('YYYY');
            const periodFromDate = moment(relativePath.periodFrom).format('DD');
            const periodFromMonth = moment(relativePath.periodFrom).format(
              'MM',
            );
            const periodFromYear = moment(relativePath.periodFrom).format(
              'YYYY',
            );
            let pathName = `${String(lastName)}.${String(firstName)}`;
            if (relativePath.collectionFrequency) {
              if (relativePath.collectionFrequency === 'W') {
                if (relativePath.projectCount === '1') {
                  pathName = `${String(lastName)}.${String(firstName)}.${String(
                    periodToYear,
                  )}.${String(periodToMonth)}.${String(periodToDate)}`;
                } else {
                  pathName = `${String(lastName)}.${String(firstName)}.${String(
                    ultraStaffDbName,
                  )}.${String(periodToYear)}.${String(periodToMonth)}.${String(
                    periodToDate,
                  )}`;
                }
              } else if (relativePath.collectionFrequency === 'M') {
                if (relativePath.projectCount === '1') {
                  pathName = `${String(lastName)}.${String(firstName)}.${String(
                    periodToYear,
                  )}.${String(periodToMonthName)}`;
                } else {
                  pathName = `${String(lastName)}.${String(firstName)}.${String(
                    ultraStaffDbName,
                  )}.${String(periodToYear)}.${String(periodToMonthName)}`;
                }
              } else if (
                relativePath.collectionFrequency === 'BM' ||
                relativePath.collectionFrequency === 'W/M' ||
                relativePath.collectionFrequency === 'BW'
              ) {
                if (relativePath.projectCount === '1') {
                  pathName = `${String(lastName)}.${String(firstName)}.${String(
                    periodFromYear,
                  )}.${String(periodFromMonth)}.${String(
                    periodFromDate,
                  )}-${String(periodToMonth)}.${String(periodToDate)}`;
                } else {
                  pathName = `${String(lastName)}.${String(firstName)}.${String(
                    ultraStaffDbName,
                  )}.${String(periodFromYear)}.${String(
                    periodFromMonth,
                  )}.${String(periodFromDate)}-${String(
                    periodToMonth,
                  )}.${String(periodToDate)}`;
                }
              } else {
                if (relativePath.projectCount === '1') {
                  pathName = `${String(lastName)}.${String(firstName)}.${String(
                    periodFromYear,
                  )}.${String(periodFromMonth)}.${String(
                    periodFromDate,
                  )}-${String(periodToMonth)}.${String(periodToDate)}`;
                } else {
                  pathName = `${String(lastName)}.${String(firstName)}.${String(
                    ultraStaffDbName,
                  )}.${String(periodFromYear)}.${String(
                    periodFromMonth,
                  )}.${String(periodFromDate)}-${String(
                    periodToMonth,
                  )}.${String(periodToDate)}`;
                }
              }
            }
            for (const [j, filePath] of relativePath.timesheetFiles.entries()) {
              if (!filePath.downloadedAt) {
                const data = await new Promise((resolve) => {
                  JSZipUtils.getBinaryContent(
                    `${process.env.REACT_APP_APIURL}${
                      MASTER_ENDPOINT.Files
                    }/${String(filePath?.id)}`,
                    (err, data) => {
                      if (err) {
                        resolve('error');
                      } else {
                        resolve(data);
                      }
                    },
                  );
                });
                if (data !== 'error') {
                  downLoadListIds.push(filePath?.id);
                  zip.file(
                    `${pathName}.${String(j)}.${String(i)}.${String(
                      filePath.extension,
                    )}`,
                    data,
                    {
                      binary: true,
                    },
                  );
                  // zip.file(`${pathName}.${String(filePath.extension)}`, data, {
                  //   binary: true,
                  // });
                }
              }
              if (
                downLoadList.length - 1 === i &&
                relativePath.timesheetFiles.length - 1 === j
              ) {
                zip.generateAsync({ type: 'blob' }).then(function (content) {
                  saveAs(content, 'riseIT.zip');
                });
              }
            }
          }
        }
        if (downLoadListIds.length) {
          const postJson = {
            fileIds: downLoadListIds,
          };
          updateData('', postJson, MASTER_ENDPOINT.Files + '/downloads', false)
            .then((resp: any) => {})
            .catch((e) => {});
        }
      })
      .catch((err: any) => {});
    setBranchDownload('');
    setLoader(false);
  };

  return (
    <LoadingOverlay active={loader} spinner text="loading...">
      <Typography component={'div'}>
        <Typography component={'div'} className={classes.subheader}>
          <Grid container sx={{ alignItems: 'center' }}>
            <Grid item md={6} sx={{ alignItems: 'center', display: 'flex' }}>
              {payPeriodList.length ? (
                <>
                  <Typography style={{ paddingBottom: '10px' }}>
                    Current Pay Period:
                    <span>
                      {consultantFilter.periodIndex === 0 ? (
                        <a
                          style={{
                            color: '#e6e2e8',
                          }}>
                          <ChevronLeftIcon />
                        </a>
                      ) : (
                        <a
                          onClick={() => {
                            // setPeriodIndex(periodIndex - 1);
                            dispatch({
                              payload: {
                                ...consultantFilter,
                                periodIndex: consultantFilter.periodIndex - 1,
                              },
                              type: UPDATE_CONSULTANT_FILTER,
                            });
                          }}
                          href="javascript:void(0);"
                          style={{
                            color: '#9d9b9e',
                          }}>
                          <ChevronLeftIcon />
                        </a>
                      )}
                      {`${moment(
                        payPeriodList[consultantFilter.periodIndex]?.startDate,
                      ).format('MMM DD')} - ${moment(
                        payPeriodList[consultantFilter.periodIndex]?.endDate,
                      ).format('MMM DD YYYY')}`}
                      {consultantFilter.periodIndex ===
                      parseInt(payPeriodList.length) - 1 ? (
                        <a
                          style={{
                            color: '#e6e2e8',
                          }}>
                          <ChevronRightIcon />
                        </a>
                      ) : (
                        <a
                          onClick={() => {
                            // setPeriodIndex(periodIndex + 1);
                            dispatch({
                              payload: {
                                ...consultantFilter,
                                periodIndex: consultantFilter.periodIndex + 1,
                              },
                              type: UPDATE_CONSULTANT_FILTER,
                            });
                          }}
                          href="javascript:void(0);"
                          style={{
                            color: '#9d9b9e',
                          }}>
                          <ChevronRightIcon />
                        </a>
                      )}
                    </span>
                  </Typography>
                  <Typography>
                    Consultants: <span>{total}</span>
                  </Typography>
                </>
              ) : (
                <Typography />
              )}
            </Grid>
            <Grid item md={6} justifyContent="end">
              <Stack direction="row" justifyContent="end" alignItems="center">
                <Typography>
                  <Tooltip title="Clear Filters">
                    <Button
                      onClick={() => {
                        dispatch({
                          payload: {
                            ...consultantFilter,
                            branch: '',
                            collectionFrequency: '',
                            hours: '',
                            lob: '',
                            order: 'asc',
                            searchWord: '',
                            skip: 0,
                            sortby: 'jobWcCode',
                            take: 100,
                            timesheets: '',
                          },
                          type: UPDATE_CONSULTANT_FILTER,
                        });
                      }}
                      sx={{
                        backgroundColor: 'transparent',
                        color: '#979598',
                        fontFamily: 'Red Hat Display Regular',
                        fontSize: '12px',
                        // padding: '0px',
                        minWidth: '0px !important',
                        paddingLeft: '0px !important',
                        paddingRight: '0px !important',
                        textTransform: 'capitalize',
                      }}>
                      <AutorenewIcon
                        sx={{ color: '#a59999', fontSize: '20px' }}
                      />
                    </Button>
                  </Tooltip>
                </Typography>
                <Typography>
                  <TablePagination
                    className={classes.defaultpagination}
                    component="div"
                    count={total}
                    page={
                      parseInt(consultantFilter.skip) /
                      parseInt(consultantFilter.take)
                    }
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    rowsPerPageOptions={[]}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </Typography>
                <Typography>
                  <ButtonGroup
                    variant="contained"
                    aria-label="split button"
                    sx={{
                      boxShadow: 'inherit !important',
                    }}>
                    <IconButton
                      className={classes.commondownload}
                      size="small"
                      aria-controls={open2 ? 'split-button-menu' : undefined}
                      aria-expanded={open2 ? 'true' : undefined}
                      aria-label="select merge strategy"
                      onClick={handleClick2}
                      aria-haspopup="menu">
                      <DownloadForOfflineIcon style={{ color: '#241A2E' }} />
                    </IconButton>
                    <Popover
                      className="commonpopover"
                      id={id2}
                      open={open2}
                      anchorEl={anchorEl2}
                      onClose={handleClose2}
                      anchorOrigin={{
                        horizontal: 'left',
                        vertical: 'bottom',
                      }}>
                      <Typography sx={{ p: 2 }}>
                        <Typography
                          sx={{
                            color: '#fff',
                            fontFamily: 'Red Hat Display Bold',
                            fontSize: '15px',
                            lineHeight: '20px',
                            paddingBottom: '5px',
                          }}>
                          Export TimeSheets
                        </Typography>
                        <Select
                          style={{ textTransform: 'capitalize' }}
                          className="common-select"
                          value={branchDownload}
                          onChange={(e: any) => {
                            setBranchDownload(e?.target?.value);
                          }}
                          displayEmpty
                          inputProps={{ 'aria-label': 'Without label' }}>
                          <MenuItem
                            value={''}
                            style={{ textTransform: 'capitalize' }}>
                            {'select branch'}
                          </MenuItem>
                          {branchList.map((row: any, index: number) => {
                            return (
                              <MenuItem
                                value={row.branch.toString()}
                                key={index}
                                style={{ textTransform: 'capitalize' }}>
                                {row.branch}
                              </MenuItem>
                            );
                          })}
                        </Select>
                        <Button variant="contained" onClick={downloadFiles}>
                          Export PDF
                        </Button>
                      </Typography>
                    </Popover>
                  </ButtonGroup>
                </Typography>
                <Typography>
                  <ButtonGroup variant="contained" aria-label="split button">
                    <Button onClick={handleClick}>Send Request</Button>
                    <Button
                      size="small"
                      aria-controls={open ? 'split-button-menu' : undefined}
                      aria-expanded={open ? 'true' : undefined}
                      aria-label="select merge strategy"
                      onClick={handleClick}
                      aria-haspopup="menu">
                      <KeyboardArrowDownSharpIcon />
                    </Button>
                    <Popover
                      className={'commonpopover1'}
                      id={id}
                      open={open}
                      anchorEl={anchorEl}
                      onClose={handleClose}
                      anchorOrigin={{
                        horizontal: 'left',
                        vertical: 'bottom',
                      }}>
                      <Typography sx={{ p: 2 }}>
                        <RadioGroup name="use-radio-group" defaultValue="first">
                          <FormControlLabel
                            value="both"
                            label="Both"
                            onChange={(e: any) => setRadio(e?.target?.value)}
                            control={
                              <Radio
                                value="both"
                                sx={{
                                  '&.Mui-checked': {
                                    color: '#005EBF',
                                  },
                                  color: '#3e3d3d',
                                }}
                                checked={radio === 'both'}
                              />
                            }
                          />
                          <FormControlLabel
                            value="hours"
                            label="Hours"
                            onChange={(e: any) => setRadio(e?.target?.value)}
                            control={
                              <Radio
                                value="hours"
                                sx={{
                                  '&.Mui-checked': {
                                    color: '#005EBF',
                                  },
                                  color: '#3e3d3d',
                                }}
                                checked={radio === 'hours'}
                              />
                            }
                          />
                          <FormControlLabel
                            value="timesheets"
                            label="TimeSheet"
                            onChange={(e: any) => setRadio(e?.target?.value)}
                            control={
                              <Radio
                                value="timesheets"
                                sx={{
                                  '&.Mui-checked': {
                                    color: '#005EBF',
                                  },
                                  color: '#3e3d3d',
                                }}
                                checked={radio === 'timesheets'}
                              />
                            }
                          />
                        </RadioGroup>
                        {/* {radio === 'timesheets' && (
                          <Select
                            style={{ textTransform: 'capitalize' }}
                            className="common-select"
                            value={branch}
                            onChange={(e: any) => {
                              setBranch(e?.target?.value);
                            }}
                            displayEmpty
                            inputProps={{ 'aria-label': 'Without label' }}>
                            <MenuItem
                              value={''}
                              style={{ textTransform: 'capitalize' }}>
                              {'select branch'}
                            </MenuItem>
                            {branchList.map((row: any, index: number) => {
                              return (
                                <MenuItem
                                  value={row.branch.toString()}
                                  key={index}
                                  style={{ textTransform: 'capitalize' }}>
                                  {row.branch}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        )} */}
                        <Button variant="contained" onClick={sendRequest}>
                          Send Request
                        </Button>
                      </Typography>
                    </Popover>
                  </ButtonGroup>
                </Typography>
              </Stack>
            </Grid>
          </Grid>
        </Typography>
        <Typography component={'div'} className={classes.defaulttable}>
          <TableContainer
            component={Paper}
            sx={{ height: 'calc(100vh - 140px)' }}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead
                style={{
                  position: 'sticky',
                  top: '0px',
                  zIndex: 3,
                }}>
                <TableRow>
                  <TableCell>
                    <Checkbox
                      sx={{
                        '& .MuiSvgIcon-root': { fontSize: 27 },
                        '&.Mui-checked': {
                          color: grey[800],
                        },
                        '&:first-child span': {
                          margin: 0,
                        },
                        color: grey[300],
                        marginLeft: '0px !important',
                        marginRight: '0px !important',
                      }}
                      onChange={(e) => setAllEvent(e.target.checked)}
                    />
                    Name
                    <Link href={'#'} onClick={() => setParamData('lastName')}>
                      <span>
                        <img src={arrowcolumns} />
                      </span>
                    </Link>
                  </TableCell>
                  <TableCell align="left">
                    Status
                    <Link
                      href={'#'}
                      onClick={() => setParamData('consultantStatus')}>
                      <span>
                        <img src={arrowcolumns} />
                      </span>
                    </Link>
                  </TableCell>
                  <TableCell align="left">
                    # of Projects
                    <Link
                      href={'#'}
                      onClick={() => setParamData('projectCount')}>
                      <span>
                        <img src={arrowcolumns} />
                      </span>
                    </Link>
                  </TableCell>
                  {!RestrictionProd && (
                    <TableCell align="left">
                      Pay Type
                      <Link
                        href={'#'}
                        onClick={() => setParamData('jobWcCode')}>
                        <span>
                          <img src={arrowcolumns} />
                        </span>
                      </Link>
                    </TableCell>
                  )}
                  <TableCell align="left">
                    <Button
                      onClick={handleClick3('bottom-end', 'lob')}
                      sx={{
                        backgroundColor: 'transparent',
                        color: '#979598',
                        fontFamily: 'Red Hat Display Regular',
                        fontSize: '12px',
                        padding: '0px',
                        // paddingRight: '20px !important',
                        textTransform: 'capitalize',
                      }}>
                      LOB{' '}
                      {open3 ? (
                        <KeyboardArrowUpIcon
                          sx={{ color: '#a59999', fontSize: '20px' }}
                        />
                      ) : (
                        <KeyboardArrowDownIcon
                          sx={{ color: '#a59999', fontSize: '20px' }}
                        />
                      )}
                    </Button>
                    <Tooltip title="Clear Filter">
                      <Button
                        onClick={() => filterChange('lob', '')}
                        sx={{
                          backgroundColor: 'transparent',
                          color: '#979598',
                          fontFamily: 'Red Hat Display Regular',
                          fontSize: '12px',
                          // padding: '0px',
                          minWidth: '0px !important',
                          paddingLeft: '0px !important',
                          paddingRight: '0px !important',
                          textTransform: 'capitalize',
                        }}>
                        <AutorenewIcon
                          sx={{ color: '#a59999', fontSize: '20px' }}
                        />
                      </Button>
                    </Tooltip>
                  </TableCell>
                  <TableCell align="left">
                    <Button
                      onClick={handleClick3('bottom-end', 'branch')}
                      sx={{
                        backgroundColor: 'transparent',
                        color: '#979598',
                        fontFamily: 'Red Hat Display Regular',
                        fontSize: '12px',
                        padding: '0px',
                        // paddingRight: '20px !important',
                        textTransform: 'capitalize',
                      }}>
                      Branch{' '}
                      {open3 ? (
                        <KeyboardArrowUpIcon
                          sx={{ color: '#a59999', fontSize: '20px' }}
                        />
                      ) : (
                        <KeyboardArrowDownIcon
                          sx={{ color: '#a59999', fontSize: '20px' }}
                        />
                      )}
                    </Button>
                    <Tooltip title="Clear Filter">
                      <Button
                        onClick={() => filterChange('branch', '')}
                        sx={{
                          backgroundColor: 'transparent',
                          color: '#979598',
                          fontFamily: 'Red Hat Display Regular',
                          fontSize: '12px',
                          minWidth: '0px !important',
                          paddingLeft: '0px !important',
                          paddingRight: '0px !important',
                          textTransform: 'capitalize',
                        }}>
                        <AutorenewIcon
                          sx={{ color: '#a59999', fontSize: '20px' }}
                        />
                      </Button>
                    </Tooltip>
                  </TableCell>
                  <TableCell align="left">
                    <Button
                      onClick={handleClick3('bottom-end', 'hours')}
                      sx={{
                        backgroundColor: 'transparent',
                        color: '#979598',
                        fontFamily: 'Red Hat Display Regular',
                        fontSize: '12px',
                        padding: '0px',
                        paddingRight: '20px !important',
                        textTransform: 'capitalize',
                      }}>
                      Hours
                      {open3 ? (
                        <KeyboardArrowUpIcon
                          sx={{ color: '#a59999', fontSize: '20px' }}
                        />
                      ) : (
                        <KeyboardArrowDownIcon
                          sx={{ color: '#a59999', fontSize: '20px' }}
                        />
                      )}
                    </Button>
                    <em>{hourTotal}</em>
                  </TableCell>
                  <TableCell align="left">
                    {/* Timesheets
                    <Link
                      href={'#'}
                      onClick={() => setParamData('isTimesheetFinalized')}>
                      <span>
                        <img src={arrowcolumns} />
                      </span>
                    </Link> */}
                    <Button
                      onClick={handleClick3('bottom-end', 'timesheets')}
                      sx={{
                        backgroundColor: 'transparent',
                        color: '#979598',
                        fontFamily: 'Red Hat Display Regular',
                        fontSize: '12px',
                        padding: '0px',
                        paddingRight: '20px !important',
                        textTransform: 'capitalize',
                      }}>
                      Timesheets
                      {open3 ? (
                        <KeyboardArrowUpIcon
                          sx={{ color: '#a59999', fontSize: '20px' }}
                        />
                      ) : (
                        <KeyboardArrowDownIcon
                          sx={{ color: '#a59999', fontSize: '20px' }}
                        />
                      )}
                    </Button>
                    <em>{timesheetTotal}</em>
                  </TableCell>
                  {/* <TableCell align="left">
                  Hours Matched{' '}
                  <span>
                    <img src={arrowcolumns} />
                  </span>
                  <em>41</em>
                </TableCell> */}
                  {/* <TableCell align="center">Hours Requested</TableCell>
                  <TableCell align="center">Timesheet Requested</TableCell> */}
                  <TableCell align="center">Manage</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {list.length ? (
                  list.map((row: any, index: number) => {
                    const fIndex = requestList.findIndex(
                      (item: any) => item === row.userId,
                    );
                    return (
                      <ListTableRow
                        row={row}
                        key={index}
                        userIds={userIds}
                        checkEvent={checkEvent}
                        checked={fIndex === -1 ? false : true}
                      />
                    );
                  })
                ) : (
                  <TableCell
                    colSpan={8}
                    sx={{
                      color: '#241a2e',
                      fontFamily: 'Red Hat Display SemiBold',
                      fontSize: '14px',
                      padding: '10px !important',
                      textAlign: 'center',
                    }}>
                    No Records Found
                  </TableCell>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Typography>
        {open3 && (
          <ClickAwayListener onClickAway={() => setOpen3(false)}>
            <Popper
              className="popwidget"
              open={open3}
              anchorEl={anchorEl3}
              placement={placement3}
              transition>
              {({ TransitionProps }) => (
                <Fade {...TransitionProps} timeout={350}>
                  <Paper>
                    {['hours', 'timesheets'].includes(filterType) ? (
                      <Typography sx={{ p: 2 }}>
                        <Typography
                          onClick={() => filterChange(filterType, '')}
                          component={'p'}
                          sx={{
                            cursor: 'pointer',
                            margin: '10px',
                            marginLeft: '3px',
                          }}>
                          Clear
                        </Typography>
                        <Typography
                          onClick={() => filterChange(filterType, 'true')}
                          component={'p'}
                          sx={{
                            cursor: 'pointer',
                            margin: '10px',
                            marginLeft: '3px',
                          }}>
                          Submitted{' '}
                          {filterType === 'hours' ? 'Hours' : 'Timesheets'}
                        </Typography>
                        <Typography
                          onClick={() => filterChange(filterType, 'false')}
                          component={'p'}
                          sx={{
                            cursor: 'pointer',
                            margin: '10px',
                            marginLeft: '3px',
                          }}>
                          Non Submitted{' '}
                          {filterType === 'hours' ? 'Hours' : 'Timesheets'}
                        </Typography>
                      </Typography>
                    ) : filterType === 'branch' ? (
                      <Typography sx={{ p: 2 }}>
                        {/* <Typography
                          onClick={() => filterChange('branch', '')}
                          component={'p'}
                          sx={{
                            cursor: 'pointer',
                            margin: '10px',
                            marginLeft: '3px',
                          }}>
                          Clear
                        </Typography> */}
                        {branchList.map((item: any) => {
                          return (
                            <Typography
                              key={item?.branch}
                              onClick={() =>
                                filterChange('branch', item?.branch)
                              }
                              component={'p'}
                              sx={{
                                cursor: 'pointer',
                                margin: '10px',
                                marginLeft: '3px',
                              }}>
                              {item?.branch}
                            </Typography>
                          );
                        })}
                        <Typography
                          key={'Blank'}
                          onClick={() => filterChange('branch', 'null')}
                          component={'p'}
                          sx={{
                            cursor: 'pointer',
                            margin: '10px',
                            marginLeft: '3px',
                          }}>
                          {'Blank'}
                        </Typography>
                      </Typography>
                    ) : filterType === 'lob' ? (
                      <Typography sx={{ p: 2 }}>
                        {/* <Typography
                          onClick={() => filterChange('lob', '')}
                          component={'p'}
                          sx={{
                            cursor: 'pointer',
                            margin: '10px',
                            marginLeft: '3px',
                          }}>
                          Clear
                        </Typography> */}
                        {lobList.map((item: any) => {
                          return (
                            <Typography
                              key={item?.branch}
                              onClick={() => filterChange('lob', item?.lob)}
                              component={'p'}
                              sx={{
                                cursor: 'pointer',
                                margin: '10px',
                                marginLeft: '3px',
                              }}>
                              {item?.lob}
                            </Typography>
                          );
                        })}
                        <Typography
                          key={'Blank'}
                          onClick={() => filterChange('lob', 'null')}
                          component={'p'}
                          sx={{
                            cursor: 'pointer',
                            margin: '10px',
                            marginLeft: '3px',
                          }}>
                          {'Blank'}
                        </Typography>
                      </Typography>
                    ) : filterType === 'collectionFrequency' ? (
                      <Typography sx={{ p: 2 }}>
                        {/* <Typography
                          onClick={() =>
                            filterChange('collectionFrequency', '')
                          }
                          component={'p'}
                          sx={{
                            cursor: 'pointer',
                            margin: '10px',
                            marginLeft: '3px',
                          }}>
                          Clear
                        </Typography> */}
                        <Typography
                          onClick={() =>
                            filterChange('collectionFrequency', 'W')
                          }
                          component={'p'}
                          sx={{
                            cursor: 'pointer',
                            margin: '10px',
                            marginLeft: '3px',
                          }}>
                          W
                        </Typography>
                        <Typography
                          onClick={() =>
                            filterChange('collectionFrequency', 'M')
                          }
                          component={'p'}
                          sx={{
                            cursor: 'pointer',
                            margin: '10px',
                            marginLeft: '3px',
                          }}>
                          M
                        </Typography>
                        <Typography
                          onClick={() =>
                            filterChange('collectionFrequency', 'W/M')
                          }
                          component={'p'}
                          sx={{
                            cursor: 'pointer',
                            margin: '10px',
                            marginLeft: '3px',
                          }}>
                          W/M
                        </Typography>
                        <Typography
                          onClick={() =>
                            filterChange('collectionFrequency', 'BM')
                          }
                          component={'p'}
                          sx={{
                            cursor: 'pointer',
                            margin: '10px',
                            marginLeft: '3px',
                          }}>
                          BM
                        </Typography>
                        <Typography
                          onClick={() =>
                            filterChange('collectionFrequency', 'BW')
                          }
                          component={'p'}
                          sx={{
                            cursor: 'pointer',
                            margin: '10px',
                            marginLeft: '3px',
                          }}>
                          BW
                        </Typography>
                        <Typography
                          onClick={() =>
                            filterChange('collectionFrequency', 'M-t/s')
                          }
                          component={'p'}
                          sx={{
                            cursor: 'pointer',
                            margin: '10px',
                            marginLeft: '3px',
                          }}>
                          M-t/s
                        </Typography>
                        <Typography
                          onClick={() =>
                            filterChange('collectionFrequency', 'W/BW')
                          }
                          component={'p'}
                          sx={{
                            cursor: 'pointer',
                            margin: '10px',
                            marginLeft: '3px',
                          }}>
                          W/BW
                        </Typography>
                        <Typography
                          onClick={() =>
                            filterChange('collectionFrequency', 'Th-Alt. W')
                          }
                          component={'p'}
                          sx={{
                            cursor: 'pointer',
                            margin: '10px',
                            marginLeft: '3px',
                          }}>
                          Th-Alt. W
                        </Typography>
                      </Typography>
                    ) : (
                      <Typography />
                    )}
                  </Paper>
                </Fade>
              )}
            </Popper>
          </ClickAwayListener>
        )}
        <Modal
          open={modalOpen}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description">
          <Box sx={style} className="model-widget">
            <Typography id="modal-modal-title" component="h2">
              Message for employee
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              <Typography component={'div'}>
                <label>Message</label>
                <TextField
                  sx={{ p: 0 }}
                  className="common-textarea-timereq"
                  id="outlined-multiline-static"
                  multiline
                  rows={3}
                  inputProps={{
                    maxLength: 250,
                  }}
                  value={message}
                  onChange={(e: any) => setMessage(e?.target?.value)}
                />
              </Typography>
            </Typography>
            <Typography sx={{ paddingTop: '15px' }} />
            <Divider />
            <Typography sx={{ paddingTop: '15px' }} />
            <Typography className="model-btn" component={'div'}>
              <Button onClick={() => setModalOpen(false)} variant="text">
                Cancel
              </Button>
              <CustomButton
                variant="contained"
                isStyle={true}
                name={'Send Request'}
                form={'sendReq'}
                onPress={submitRequest}
                loading={loading}
              />
            </Typography>
          </Box>
        </Modal>
      </Typography>
    </LoadingOverlay>
  );
};

export default Consultants;
