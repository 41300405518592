import { yupResolver } from '@hookform/resolvers/yup';
import {
  Avatar,
  AvatarGroup,
  Box,
  Button,
  Chip,
  CircularProgress,
  Divider,
  Fade,
  Grid,
  MenuItem,
  Modal,
  Paper,
  Select,
  Table,
  TableBody,
  TextareaAutosize,
  TextField,
} from '@mui/material';
import Popper, { PopperPlacementType } from '@mui/material/Popper';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import profileimg from 'assets/images/profileimg.png';
import CheckboxCom from 'components/CheckBox';
import CustomButton from 'components/CustomButton/customButton';
import CustomInput from 'components/CustomInput/customInput';
import DatePicker from 'components/DatePicker';
import InfinityScroll from 'components/InfinityScroll';
import { AdminPrivateRoutes } from 'config';
import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';
import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import CurrencyInput from 'react-currency-input-field';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
// import HeaderLogo from 'assets/images/RiseIt.png';
import LoadingOverlay from 'react-loading-overlay';
import { NavLink } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getAllListData, updateData } from 'services/CommonServices';
import { useAppSelector } from 'store/hooks';
import { ApplyLeaveForm1 } from 'types';
import { alertOptions, roundOfData } from 'utils/Config';
import useWindowDimensions from 'utils/Config';
import { MASTER_ENDPOINT, PAYSHEET } from 'utils/Constant';
import { putRequest } from 'utils/Handlers';
import { ApplyLeaveSchema1 } from 'utils/ValidatorSchema';

import useStyles from './style';

const style = {
  bgcolor: 'background.paper',
  borderRadius: '10px',
  boxShadow: 24,
  left: '50%',
  p: 4,
  position: 'absolute' as const,
  top: '50%',
  transform: 'translate(-50%, -50%)',
  width: 486,
};

const pQuestionStyle = {
  bgcolor: 'background.paper',
  borderRadius: '10px',
  boxShadow: 24,
  // height: '90vh',
  left: '50%',
  maxHeight: 500,
  // width: 750,
  maxWidth: 550,
  overflowY: 'scroll !important',
  p: 4,
  position: 'absolute' as const,
  top: '50%',
  transform: 'translate(-50%, -50%)',
};

const projectStyle = {
  bgcolor: 'background.paper',
  borderRadius: '10px',
  boxShadow: 24,
  height: '90vh',
  left: '50%',
  maxHeight: 500,
  overflowY: 'scroll !important',
  p: 4,
  position: 'absolute' as const,
  top: '50%',
  transform: 'translate(-50%, -50%)',
  width: 750,
};

const styleLeaveApplication = {
  bgcolor: 'background.paper',
  borderRadius: '10px',
  boxShadow: 24,
  height: '90vh',
  left: '50%',
  maxHeight: 750,
  overflowY: 'scroll !important',
  p: 4,
  position: 'absolute' as const,
  top: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#f8f8fa',
    color: '#979598',
    fontFamily: 'Red Hat Display SemiBold',
    fontSize: 12,
    lineHeight: '15px',
    padding: 8,
    paddingBottom: 12,
    paddingLeft: 0,
    paddingTop: 12,
  },
  [`&.${tableCellClasses.body}`]: {
    backgroundColor: '#fff',
    borderColor: '#d4d4d6',
    borderWidth: 1,
    color: '#241A2E',
    fontFamily: 'Red Hat Display Medium',
    fontSize: 13,
    lineHeight: '15px',
    padding: 5,
    paddingBottom: 15,
    paddingTop: 25,
    verticalAlign: 'top',
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  // hide last border
  //   '&:first-child td': {
  //     border: 0,
  //   },
}));

const bStyle = {
  border: '1.5px solid #E0E0E0',
  borderRadius: '8px',
  color: '#292929',
  fontFamily: 'Red Hat Display Bold',
  fontSize: '14px',
  margin: '0px 10px 10px',
  textTransform: 'capitalize',
};

const leaveStyle = {
  closeText: {
    color: '#292929',
    fontFamily: 'Red Hat Display Bold',
    textDecoration: 'underLine',
  },
  mediumText: {
    color: '#292929',
    fontFamily: 'Red Hat Display Regular',
    fontSize: '15px',
  },
  normalText: {
    color: '#292929',
    fontFamily: 'Red Hat Display Regular',
    fontSize: '12px',
  },
};

const staticParam = {
  organization: '',
  searchWord: '',
  skip: 0,
  take: 20,
};

const Queue: React.FC<any> = () => {
  const classes = useStyles();
  const { height } = useWindowDimensions();
  const [param, setParam] = React.useState(staticParam);
  const [open, setOpen] = useState<boolean>(false);
  const [openLeaveApplicationModel, setOpenLeaveApplicationModel] =
    React.useState(false);
  const [editId, setEditId] = useState<any>({
    checkboxStatus: '',
    id: '',
    ifYesCheckboxStatus: '',
    leaveId: '',
    status: '',
    type: '',
    userId: '',
    workingWhileOnLeave: false,
  });
  const [note, setNote] = useState<string>('');
  const [piBuffer, setPiBuffer] = useState<any>({
    comments: '',
    item: {},
    payout: 'approved',
    status: '',
    visible: false,
  });
  const [extendedTo, setExtendedTo] = useState<any>('');
  const [returnTo, setReturnTo] = useState<any>('');
  const [leaveTypes, setLeaveTypes] = React.useState<any>([]);
  const [list, setList] = useState<any>([]);
  const [total, setTotal] = useState<number>(0);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setEditId({
      checkboxStatus: '',
      id: '',
      ifYesCheckboxStatus: '',
      leaveId: '',
      status: '',
      type: '',
      userId: '',
      workingWhileOnLeave: false,
    });
    setNote('');
    setExtendedTo('');
    setReturnTo('');
  };
  const handleCloseLeaveApplicationModel = () => {
    reset();
    setOpenLeaveApplicationModel(false);
  };

  const { userInfo }: any = useAppSelector((store: any) => store.userLogin);
  const [loader, setLoader] = React.useState<boolean>(false);
  const [loader1, setLoader1] = React.useState<boolean>(true);

  const getQues = () => {
    if (loader1) {
      setLoader1(false);
      setLoader(true);
    }
    if (param.skip === 0) {
      setList([]);
      setTotal(0);
      setLoader(true);
    }
    let url =
      MASTER_ENDPOINT.Queue + '?skip=' + param.skip + '&take=' + param.take;
    if (param.searchWord) {
      url += `&search=${String(param.searchWord)}`;
    }
    if (param.organization !== 'all') {
      url += '&organization=' + param.organization;
    }
    getAllListData(url)
      .then((resp: any) => {
        const mergedData =
          param.skip === 0 ? [...resp.data] : [...list, ...resp.data];
        setList(mergedData);
        setTotal(resp?.total);
        setLoader(false);
      })
      .catch((err: any) => {
        setLoader(false);
      });
  };

  useEffect(() => {
    getLeaveTypes();
    getUserList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { companyStatusQueue }: any = useAppSelector(
    (store: any) => store.userLogin,
  );

  useEffect(() => {
    if (companyStatusQueue !== param.organization) {
      setParam({
        ...staticParam,
        organization: companyStatusQueue,
        skip: 0,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyStatusQueue]);

  useEffect(() => {
    if (param.organization) {
      getQues();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [param]);

  const { searchText }: any = useAppSelector((store: any) => store.userLogin);

  const [searchLoad, setSearchLoad] = React.useState(false);
  useEffect(() => {
    if (searchLoad && param.searchWord !== searchText) {
      setParam({
        ...staticParam,
        organization: param.organization,
        searchWord: searchText,
      });
    }
    setSearchLoad(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText, searchLoad]);

  const validateEmail = (mail) => {
    const emailCheck =
      /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i;
    if (emailCheck.test(mail)) {
      return true;
    }
    return false;
  };

  const [loading, setLoading] = useState<boolean>(false);
  const pQuestionApproveAndDenied = () => {
    setLoading(true);
    const tagUrl = `${
      window.location.origin
    }/admin/consultants/sub?payperiodId=${
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      pQuestion?.data?.payrollQuestion?.payperiodId
        ? pQuestion.data.payrollQuestion.payperiodId
        : ''
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    }&userId=${pQuestion?.data?.userId}`;
    const postJson = {
      adjustedAmount: pQuestion?.adjustedAmount
        ? parseFloat(pQuestion?.adjustedAmount)
        : 0,
      currentEarnings3Amount1: pQuestion?.currentEarnings3Amount1
        ? parseFloat(pQuestion?.currentEarnings3Amount1)
        : 0,
      note: pQuestion?.notes,
      payrollQuestionId: pQuestion?.data?.payrollQuestion?.id,
      pqPageURL: tagUrl,
      status: pQuestion?.status,
    };
    updateData(
      pQuestion?.data?.id,
      postJson,
      MASTER_ENDPOINT.Queue + '/payroll-questions/exceptions',
      true,
    )
      .then((resp: any) => {
        setParam({ ...staticParam, organization: param.organization });
        pQuestionClose();
        setLoading(false);
      })
      .catch((err: any) => {
        setLoading(false);
      });
  };

  const submitNote = () => {
    if (
      editId?.type === 'piBufferPayoutRequest' ||
      editId?.type === 'payrollQuestionNotification'
    ) {
      setLoading(true);
      const postJson = {
        comments: note,
      };
      updateData(editId.id, postJson, MASTER_ENDPOINT.Queue + '/complete', true)
        .then((resp: any) => {
          setParam({ ...staticParam, organization: param.organization });
          handleClose();
          setLoading(false);
        })
        .catch((err: any) => {
          setLoading(false);
        });
    } else if (editId?.type === 'projectEnd') {
      if (userInfo.roleId === 5) {
        if (editId.status === 'confirmed') {
          if (!editId?.checkboxStatus) {
            toast.error('Please check NLE', alertOptions);
            return;
          }
          if (
            editId?.checkboxStatus === 'yes' &&
            !editId?.ifYesCheckboxStatus
          ) {
            toast.error('Please check eligible for rehire', alertOptions);
            return;
          }
        }
        setLoading(true);
        let postJson = {};
        if (editId.status === 'confirmed') {
          postJson = {
            comments: note,
            isEligibleForRehire:
              editId?.checkboxStatus === 'yes' ? true : false,
            nle: editId?.ifYesCheckboxStatus === 'yes' ? true : false,
          };
        } else {
          postJson = {
            comments: note,
          };
        }
        updateData(
          editId.id,
          postJson,
          MASTER_ENDPOINT.Queue + '/project-end',
          true,
        )
          .then((resp: any) => {
            setParam({ ...staticParam, organization: param.organization });
            handleClose();
            setLoading(false);
          })
          .catch((err: any) => {
            setLoading(false);
          });
      } else {
        setLoading(true);
        const postJson = {
          comments: note,
        };
        updateData(
          editId.id,
          postJson,
          MASTER_ENDPOINT.Queue + '/complete',
          true,
        )
          .then((resp: any) => {
            setParam({ ...staticParam, organization: param.organization });
            handleClose();
            setLoading(false);
          })
          .catch((err: any) => {
            setLoading(false);
          });
      }
    } else if (editId?.type === 'newEmployee') {
      getAllListData(
        `${MASTER_ENDPOINT.Admins}/consultant-profile/?id=${String(
          editId.userId,
        )}`,
      ).then((resp: any) => {
        const employeeInfo = resp?.data?.[0];

        if (userInfo.roleId === 2) {
          setLoading(true);
          const postJson = {
            comments: note,
          };
          updateData(
            editId.id,
            postJson,
            MASTER_ENDPOINT.Queue + '/complete',
            true,
          )
            .then((resp: any) => {
              setParam({ ...staticParam, organization: param.organization });
              handleClose();
              setLoading(false);
            })
            .catch((err: any) => {
              setLoading(false);
            });
        } else {
          if (validateEmail(editId.email)) {
            setLoading(true);
            const postJson = {
              access: true,
              alternateEmail: editId.email,
              basePayRate: employeeInfo.basePayRate,
              baseWage: employeeInfo.baseWage,
              comments: note,
              eligibleForSickLeave: employeeInfo.eligibleForSickLeave,
              eligibleSickHours: employeeInfo.eligibleSickHours,
              email: editId.email,
              workAuth: employeeInfo.workAuth,
            };
            updateData(
              editId.id,
              postJson,
              MASTER_ENDPOINT.Admins + '/access',
              true,
            )
              .then((resp: any) => {
                setParam({ ...staticParam, organization: param.organization });
                handleClose();
                setLoading(false);
              })
              .catch((err: any) => {
                setLoading(false);
              });
          } else {
            toast.error('Please enter valid email', alertOptions);
            return;
          }
        }
      });
    } else {
      if (editId.status === 'extended' && userInfo.roleId === 6) {
        if (!extendedTo) {
          toast.error('Please select extend date', alertOptions);
          return;
        }
        if (!returnTo) {
          toast.error('Please select return date', alertOptions);
          return;
        }
      }
      if (editId.status === 'denied') {
        if (!note) {
          toast.error('Please add notes', alertOptions);
          return;
        }
      }
      setLoading(true);
      if (
        userInfo.roleId === 2 ||
        (userInfo.roleId === 4 && editId.status === 'returned')
      ) {
        let postJson = {};
        if (editId.workingWhileOnLeave && editId.status === 'approved') {
          postJson = {
            comments: note,
            isWorkingOnLeaveApproved: true,
          };
        } else {
          postJson = {
            comments: note,
          };
        }
        updateData(
          editId.id,
          postJson,
          MASTER_ENDPOINT.Queue + '/complete',
          true,
        )
          .then((resp: any) => {
            setParam({ ...staticParam, organization: param.organization });
            handleClose();
            setLoading(false);
          })
          .catch((err: any) => {
            setLoading(false);
          });
      } else {
        let postJson = {};
        if (editId.workingWhileOnLeave && editId.status === 'approved') {
          postJson = {
            comments: note,
            extendedTo: extendedTo
              ? moment(new Date(extendedTo)).format('YYYY-MM-DD')
              : '',
            isWorkingOnLeaveApproved: true,
            queueId: editId.id,
            returnDate: returnTo
              ? moment(new Date(returnTo)).format('YYYY-MM-DD')
              : '',
            status: editId.status,
          };
        } else {
          postJson = {
            comments: note,
            extendedTo: extendedTo
              ? moment(new Date(extendedTo)).format('YYYY-MM-DD')
              : '',
            queueId: editId.id,
            returnDate: returnTo
              ? moment(new Date(returnTo)).format('YYYY-MM-DD')
              : '',
            status: editId.status,
          };
        }
        updateData(editId.leaveId, postJson, MASTER_ENDPOINT.LeavesStatus, true)
          .then((resp: any) => {
            setParam({ ...staticParam, organization: param.organization });
            handleClose();
            setLoading(false);
          })
          .catch((err: any) => {
            setLoading(false);
          });
      }
    }
  };

  const getLeaveRequest = (row: any) => {
    const {
      comments,
      fromDate,
      returnDate,
      returnToUsOn,
      toDate,
      travelingOutsideUS,
      wishToBePaidOnLeave,
      workingWhileOnLeave,
      eSignature,
      onLeaveCountry,
      modeOfCommunication,
      communicateVia,
      payableDays,
      workingDays,
    } = row;
    const lTypes = [];
    if (row?.leaveTypes && row?.leaveTypes.length) {
      row.leaveTypes.map((item: any) => {
        if (Number(item.leaveTypeId) === 5) {
          setValue('reasonComments', item.reason);
        }
        lTypes.push({
          id: item.id,
          leaveTypeId: item.leaveTypeId.toString(),
          reason: item.reason,
        });
      });
    }
    setValue('workingDays', workingDays);
    setValue('payableDays', payableDays);
    setValue('communicateVia', communicateVia);
    setValue('modeOfCommunication', modeOfCommunication);
    setValue('onLeaveCountry', onLeaveCountry);
    setValue('comments', comments);
    setValue('fromDate', fromDate);
    setValue('leaveTypes', lTypes);
    setValue('returnDate', returnDate);
    setValue('returnToUsOn', returnToUsOn);
    setValue('toDate', toDate);
    setValue('travelingOutsideUS', travelingOutsideUS ? 'yes' : 'no');
    setValue('wishToBePaidOnLeave', wishToBePaidOnLeave);
    setValue('workingWhileOnLeave', workingWhileOnLeave ? 'yes' : 'no');
    setValue('eSignature', eSignature);
  };

  const handlePdfDownload = (row: any) => {
    const leave = row.leave;
    let weeklyDays = '';
    if (leave?.workingDays && leave?.workingDays.length) {
      leave?.workingDays.map((item: any) => {
        weeklyDays +=
          `(${moment(item?.fromDate).format('MM-DD-YYYY')} - ${moment(
            item?.toDate,
          ).format('MM-DD-YYYY')}) ` + ', ';
      });
    }
    if (weeklyDays) {
      weeklyDays = weeklyDays.replace(/,\s*$/, '');
    }
    let payableDays = '';
    if (leave?.payableDays && leave?.payableDays.length) {
      leave?.payableDays.map((item: any) => {
        payableDays +=
          `(${moment(item?.fromDate).format('MM-DD-YYYY')} - ${moment(
            item?.toDate,
          ).format('MM-DD-YYYY')}) ` + ', ';
      });
    }
    if (payableDays) {
      payableDays = payableDays.replace(/,\s*$/, '');
    }
    let leaveTypesText = '';
    let leaveTypesReason = '';
    if (leave?.leaveTypes && leave?.leaveTypes.length) {
      leave?.leaveTypes.map((item: any) => {
        if (Number(item.leaveTypeId) === 5) {
          leaveTypesReason = item.reason;
        }
        leaveTypesText += item?.leaveType?.type + ', ';
      });
    }
    if (leaveTypesText) {
      leaveTypesText = leaveTypesText.replace(/,\s*$/, '');
    }
    const doc = new jsPDF();
    const headFont = ['helvetica', 'bold'];
    doc.setFontSize(15);
    doc.setFont(headFont[0], headFont[1]);
    doc.setTextColor(0, 0, 0);
    doc.text('LEAVE REQUEST FORM', 16, 15);
    autoTable(doc, {
      body: [
        [
          'Consultant Name',
          String(leave.user.firstName) + ' ' + String(leave.user.lastName),
        ],
        [
          'Company Name',
          leave.user?.ultraStaffDbName
            ? ' ' + String(leave.user?.ultraStaffDbName)
            : '-',
        ],
        ['First Day of Leave', moment(leave.fromDate).format('MM-DD-YYYY')],
        ['Last Day of Leave', moment(leave.toDate).format('MM-DD-YYYY')],
        [
          'Exact Return to Work Date',
          moment(leave.returnDate).format('MM-DD-YYYY'),
        ],
        [
          'Will you be traveling outside the U.S.?',
          leave.travelingOutsideUS === true
            ? 'Yes'
            : leave.travelingOutsideUS === false
            ? 'No'
            : '-',
        ],
        [
          'Enter date of return/re-entry to the U.S.',
          leave.returnToUsOn
            ? moment(leave.returnToUsOn).format('MM-DD-YYYY')
            : '-',
        ],
        [
          'Will you be Working While on Leave?',
          leave.workingWhileOnLeave === true
            ? 'Yes'
            : leave.workingWhileOnLeave === false
            ? 'No'
            : '-',
        ],
        [
          'If yes, please indicate the dates you will be working',
          weeklyDays ? weeklyDays : '-',
        ],
        [
          'Do You Wish to be Paid While on Leave?',
          leave.wishToBePaidOnLeave === 'yes'
            ? 'Yes'
            : leave.wishToBePaidOnLeave === 'no'
            ? 'No'
            : leave.wishToBePaidOnLeave === 'partialyPaid'
            ? 'Partially Paid'
            : '-',
        ],
        [
          'Paid While on Leave From and To Date',
          payableDays ? payableDays : '-',
        ],
        ['Reason for Leave?', leaveTypesText ? leaveTypesText : '-'],
        ['Reason comment', leaveTypesReason ? leaveTypesReason : '-'],
        ['Country of Leave', leave.onLeaveCountry ? leave.onLeaveCountry : '-'],
        [
          'Preferred Method of Communication While on Leave',
          leave.modeOfCommunication ? leave.modeOfCommunication : '-',
        ],
        [
          'Enter Phone Number',
          leave.communicateVia ? leave.communicateVia : '-',
        ],
        ['Add Comment', leave.comments ? leave.comments : '-'],
      ],
      columnStyles: {
        0: {
          cellWidth: 95,
          fillColor: [255, 255, 255],
          font: 'helvetica',
          fontSize: 11,
          fontStyle: 'bold',
        },
        1: {
          cellWidth: 95,
          fillColor: [255, 255, 255],
          font: 'helvetica',
          fontSize: 11,
          fontStyle: 'normal',
        },
      },
      margin: { top: 20 },
      styles: { fillColor: [255, 255, 255] },
    });
    if (leave.eSignature) {
      const finalY = (doc as any).lastAutoTable.finalY;
      doc.setFontSize(12);
      doc.setFont(headFont[0], headFont[1]);
      doc.setTextColor(0, 0, 0);
      doc.text('E signature', 170, finalY + 15);
      const imag = new Image();
      const src1 = leave.eSignature;
      imag.src = src1;
      doc.addImage(imag, 'png', 165, finalY + 23, 35, 15);
    }
    doc.save(
      String(leave.user.firstName) +
        ' ' +
        String(leave.user.lastName) +
        '' +
        '-leavedetails.pdf',
    );
  };

  const getLeaveTypes = () => {
    getAllListData(MASTER_ENDPOINT.LeaveTypes)
      .then((resp: any) => {
        if (resp?.data) {
          const leaveList = [];
          resp?.data.map((row: any) => {
            leaveList.push({
              disabled: true,
              label: row.type,
              value: parseInt(row.id),
            });
          });
          setLeaveTypes(leaveList);
        }
      })
      .catch((err: any) => {});
  };

  const showLeaveInfo = (row) => {
    setOpenLeaveApplicationModel(true);
    if (row?.leave) getLeaveRequest(row.leave);
  };

  const [sickHoursRequest, setSickHoursRequest] = React.useState<any>({
    note: '',
    open: false,
    rowId: '',
    status: '',
    timesheetOutSickId: '',
  });

  const submitSickHoursRequest = async () => {
    const url =
      MASTER_ENDPOINT.Queue +
      '/' +
      sickHoursRequest.rowId +
      '/' +
      MASTER_ENDPOINT.SickTimeQueueStatus;
    try {
      await putRequest({
        data: {
          note: sickHoursRequest.note,
          status: sickHoursRequest.status,
          timesheetOutSickId: sickHoursRequest.timesheetOutSickId,
        },
        url,
      });
      toast.success('Sick hours request updated successfully', alertOptions);
    } catch (error) {
      toast.error('Failed to update sick hours request', alertOptions);
    } finally {
      setSickHoursRequest({
        note: '',
        open: false,
        rowId: '',
        status: '',
        timesheetOutSickId: '',
      });
      getQues();
    }
  };

  const [pQuestion, setPQuestion] = React.useState<any>({
    adjustedAmount: '',
    currentEarnings3Amount1: '',
    data: {},
    notes: '',
    open: false,
    payrollData: {},
    status: '',
  });

  const pquestInfo = (item: any, sts: string) => {
    getAllListData(
      PAYSHEET.Payroll +
        '/summary?userId=' +
        item.userId +
        '&payperiodId=' +
        item?.payrollQuestion?.payperiodId,
    )
      .then((resp: any) => {
        setPQuestion({
          adjustedAmount: '',
          currentEarnings3Amount1: '',
          data: item,
          notes: '',
          open: true,
          payrollData: resp?.data?.[0],
          status: sts,
        });
      })
      .catch((err: any) => {});
  };

  const pQuestionClose = () => {
    setPQuestion({
      adjustedAmount: '',
      currentEarnings3Amount1: '',
      data: {},
      notes: '',
      open: false,
      payrollData: {},
      status: '',
    });
  };

  const [projectInfoModel, setProjectInfoModel] = React.useState(false);
  const [projectInfo, setProjectInfo] = React.useState<any>({});
  const projectInfoClose = () => {
    setProjectInfo({});
    setProjectInfoModel(false);
  };
  const [projectLoader, setProjectLoader] = React.useState<boolean>(false);
  const showProjectInfo = (row: any) => {
    setProjectLoader(true);
    if (row?.userId) {
      getAllListData(MASTER_ENDPOINT.Admins + '?id=' + row?.userId)
        .then((resp: any) => {
          if (resp?.data) {
            setProjectInfo(resp?.data?.[0]);
            setProjectLoader(false);
          }
        })
        .catch((err: any) => {
          setProjectLoader(false);
        });
      setProjectInfoModel(true);
    }
  };

  const resetForm = () => {
    reset();
    handleCloseLeaveApplicationModel();
  };

  const renderButtton = (row: any) => {
    if (row?.type === 'payrollQuestion') {
      return (
        <>
          <Button
            color="inherit"
            onClick={() => pquestInfo(row, 'approved')}
            variant="outlined"
            sx={bStyle}>
            Approve
          </Button>
          <Button
            color="inherit"
            onClick={() => pquestInfo(row, 'adjusted')}
            variant="outlined"
            sx={bStyle}>
            Deny
          </Button>
        </>
      );
    } else if (row?.type === 'piBufferPayoutRequest') {
      if (userInfo.roleId === 4) {
        return (
          <>
            <Button
              color="inherit"
              onClick={() => {
                row?.piBufferRequest?.piBuffer.map((item: any) => {
                  item.approvedBufferAmount = item.requestedBufferAmount;
                });
                setPiBuffer({
                  comments: '',
                  item: row,
                  payout: 'approved',
                  status: 'approved',
                  visible: true,
                });
              }}
              variant="outlined"
              sx={bStyle}>
              Manage
            </Button>
            <Button
              color="inherit"
              onClick={() => {
                row?.piBufferRequest?.piBuffer.map((item: any) => {
                  item.approvedBufferAmount = item.requestedBufferAmount;
                });
                setPiBuffer({
                  comments: '',
                  item: row,
                  payout: 'approved',
                  status: 'denied',
                  visible: true,
                });
              }}
              variant="outlined"
              sx={bStyle}>
              Deny
            </Button>
          </>
        );
      } else {
        return (
          <Button
            color="inherit"
            onClick={() => {
              setEditId({
                id: row.id,
                leaveId: '',
                status: 'complete',
                type: row?.type,
              });
              handleOpen();
            }}
            variant="outlined"
            sx={bStyle}>
            complete
          </Button>
        );
      }
    } else if (row?.type === 'newEmployee') {
      return (
        <Button
          color="inherit"
          onClick={() => {
            setEditId({
              alternateEmail: row?.user?.alternativeEmail,
              email: row?.user?.alternativeEmail,
              id: row.id,
              status: row?.status,
              type: row?.type,
              userId: row?.userId,
              workingWhileOnLeave: '',
            });
            handleOpen();
          }}
          variant="outlined"
          sx={bStyle}>
          complete
        </Button>
      );
    } else if (row?.type === 'projectEnd') {
      if (userInfo.roleId === 5) {
        return (
          <>
            <Button
              color="inherit"
              onClick={() => {
                setEditId({
                  id: row.id,
                  status: 'confirmed',
                  type: row?.type,
                });
                handleOpen();
              }}
              variant="outlined"
              sx={bStyle}>
              Confirmed
            </Button>
            <Button
              color="inherit"
              onClick={() => {
                setEditId({
                  id: row.id,
                  status: 'extended',
                  type: row?.type,
                });
                handleOpen();
              }}
              variant="outlined"
              sx={bStyle}>
              Extended
            </Button>
          </>
        );
      } else {
        return (
          <Button
            color="inherit"
            onClick={() => {
              setEditId({
                id: row.id,
                status: row?.status,
                type: row?.type,
              });
              handleOpen();
            }}
            variant="outlined"
            sx={bStyle}>
            complete
          </Button>
        );
      }
    } else if (row?.type === 'sickHoursRequest') {
      return (
        <>
          <Button
            color="inherit"
            onClick={() =>
              setSickHoursRequest((prev) => ({
                ...prev,
                open: true,
                rowId: row.id,
                status: 'approved',
                timesheetOutSickId: row.timesheetOutSickId,
              }))
            }
            variant="outlined"
            sx={bStyle}>
            Approve
          </Button>
          <Button
            color="inherit"
            onClick={() =>
              setSickHoursRequest((prev) => ({
                ...prev,
                open: true,
                rowId: row.id,
                status: 'denied',
                timesheetOutSickId: row.timesheetOutSickId,
              }))
            }
            variant="outlined"
            sx={bStyle}>
            Deny
          </Button>
        </>
      );
    }
    switch (userInfo.roleId) {
      case 2:
        return (
          <Button
            color="inherit"
            onClick={() => {
              setEditId({
                id: row.id,
                leaveId: row.leaveId,
                status: row?.leave?.status,
                type: row?.type,
                workingWhileOnLeave: row?.leave?.workingWhileOnLeave,
              });
              handleOpen();
            }}
            variant="outlined"
            sx={bStyle}>
            complete
          </Button>
        );
      case 4:
        if (row.type === 'leaveCancel') {
          return (
            <>
              <Button
                color="inherit"
                onClick={() => {
                  setEditId({
                    id: row.id,
                    leaveId: row.leaveId,
                    status: 'cancelled',
                    type: row?.type,
                    workingWhileOnLeave: row?.leave?.workingWhileOnLeave,
                  });
                  handleOpen();
                }}
                variant="outlined"
                sx={bStyle}>
                Approve
              </Button>
              <Button
                color="inherit"
                onClick={() => {
                  setEditId({
                    id: row.id,
                    leaveId: row.leaveId,
                    status: 'denied',
                    type: row?.type,
                    workingWhileOnLeave: row?.leave?.workingWhileOnLeave,
                  });
                  handleOpen();
                }}
                variant="outlined"
                sx={bStyle}>
                Deny
              </Button>
            </>
          );
        } else {
          return (
            <Button
              color="inherit"
              onClick={() => {
                setEditId({
                  id: row.id,
                  leaveId: row.leaveId,
                  status: row?.leave?.status,
                  type: row?.type,
                  workingWhileOnLeave: row?.leave?.workingWhileOnLeave,
                  // row?.leave?.status === 'approved' ? 'approved' : 'returned',
                });
                handleOpen();
              }}
              variant="outlined"
              sx={bStyle}>
              complete
            </Button>
          );
        }
      case 5:
        return (
          <>
            <Button
              color="inherit"
              onClick={() => {
                setEditId({
                  id: row.id,
                  leaveId: row.leaveId,
                  status: 'approved',
                  type: row?.type,
                  workingWhileOnLeave: row?.leave?.workingWhileOnLeave,
                });
                handleOpen();
              }}
              variant="outlined"
              sx={bStyle}>
              Approve
            </Button>
            <Button
              color="inherit"
              onClick={() => {
                setEditId({
                  id: row.id,
                  leaveId: row.leaveId,
                  status: 'denied',
                  type: row?.type,
                  workingWhileOnLeave: row?.leave?.workingWhileOnLeave,
                });
                handleOpen();
              }}
              variant="outlined"
              sx={bStyle}>
              Deny
            </Button>
          </>
        );
      case 6:
        if (row.type === 'leaveReturn') {
          return (
            <>
              <Button
                color="inherit"
                onClick={() => {
                  setEditId({
                    id: row.id,
                    leaveId: row.leaveId,
                    status: 'returned',
                    type: row?.type,
                    workingWhileOnLeave: row?.leave?.workingWhileOnLeave,
                  });
                  handleOpen();
                }}
                variant="outlined"
                sx={bStyle}>
                Confirmed
              </Button>
              <Button
                color="inherit"
                onClick={() => {
                  setEditId({
                    id: row.id,
                    leaveId: row.leaveId,
                    status: 'extended',
                    type: row?.type,
                    workingWhileOnLeave: row?.leave?.workingWhileOnLeave,
                  });
                  handleOpen();
                }}
                variant="outlined"
                sx={bStyle}>
                Extended
              </Button>
            </>
          );
        } else if (row.type === 'leaveCancel') {
          return (
            <>
              <Button
                color="inherit"
                onClick={() => {
                  setEditId({
                    id: row.id,
                    leaveId: row.leaveId,
                    status: 'cancelled',
                    type: row?.type,
                    workingWhileOnLeave: row?.leave?.workingWhileOnLeave,
                  });
                  handleOpen();
                }}
                variant="outlined"
                sx={bStyle}>
                Approve
              </Button>
              <Button
                color="inherit"
                onClick={() => {
                  setEditId({
                    id: row.id,
                    leaveId: row.leaveId,
                    status: 'denied',
                    type: row?.type,
                    workingWhileOnLeave: row?.leave?.workingWhileOnLeave,
                  });
                  handleOpen();
                }}
                variant="outlined"
                sx={bStyle}>
                Deny
              </Button>
            </>
          );
        } else {
          return (
            <Button
              color="inherit"
              onClick={() => {
                setEditId({
                  id: row.id,
                  leaveId: row.leaveId,
                  status: row?.leave?.status,
                  type: row?.type,
                  workingWhileOnLeave: row?.leave?.workingWhileOnLeave,
                  // row?.leave?.status === 'approved' ? 'approved' : 'returned',
                });
                handleOpen();
              }}
              variant="outlined"
              sx={bStyle}>
              complete
            </Button>
          );
        }
    }
    return;
  };

  const { control, formState, reset, watch, setValue } =
    useForm<ApplyLeaveForm1>({
      defaultValues: {
        comments: '',
        communicateVia: '',
        eSignature: '',
        fromDate: null,
        leaveTypes: [],
        modeOfCommunication: '',
        paidOnLeaveComments: 'paid',
        payableDays: [
          {
            fromDate: '',
            toDate: '',
          },
        ],
        reasonComments: '',
        returnDate: null,
        returnToUsOn: null,
        toDate: null,
        travelingOutsideUS: '',
        wishToBePaidOnLeave: '',
        workingDays: [
          {
            fromDate: '',
            toDate: '',
          },
        ],
        workingWhileOnLeave: '',
      },
      reValidateMode: 'onChange',
      resolver: yupResolver(ApplyLeaveSchema1),
    });

  const {
    fields: workingDaysArray,
    // append: workingDaysArrayAppend,
    // remove: workingDaysArrayRomove,
  } = useFieldArray({ control, name: 'workingDays' });

  const {
    fields: payableDaysArray,
    // append: payableDaysArrayAppend,
    // remove: payableDaysArrayRomove,
  } = useFieldArray({ control, name: 'payableDays' });

  const headRow = () => {
    return (
      <TableHead>
        <TableRow>
          <StyledTableCell style={{ width: 60 }} />
          <StyledTableCell style={{ width: 400 }}>Task Item:</StyledTableCell>
          <StyledTableCell style={{ width: 200 }}>Status:</StyledTableCell>
          <StyledTableCell style={{ width: 200 }}>Created:</StyledTableCell>
          <StyledTableCell style={{ width: 150 }}>Assigned:</StyledTableCell>
          <StyledTableCell align="right" />
        </TableRow>
      </TableHead>
    );
  };

  const [showAssignee, setshowAssignee] = useState<any>({
    json: {},
    open: false,
  });
  const [userList, setUserList] = useState<any>([]);
  const [id, setId] = useState<string>('');
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null,
  );
  const [placement, setPlacement] = React.useState<PopperPlacementType>();

  const handleClick =
    (newPlacement: PopperPlacementType, json: any) =>
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
      setshowAssignee({
        json,
        open: !showAssignee.open,
      });
      setId('');
      setPlacement(newPlacement);
    };

  const getUserList = () => {
    getAllListData(MASTER_ENDPOINT.Admins + '?roleId=' + userInfo.roleId)
      .then((resp: any) => {
        if (resp?.data) {
          setUserList(
            resp?.data.filter((item: any) => item.id !== userInfo.id),
          );
        }
      })
      .catch((err: any) => {});
  };

  const assignToMe = () => {
    updateData(
      showAssignee?.json?.id,
      {
        assignTo: [userInfo?.id],
      },
      MASTER_ENDPOINT.Queue + '/assign',
      true,
    )
      .then((resp: any) => {
        setshowAssignee({
          json: {},
          open: !showAssignee.open,
        });
        setId('');
        setParam({ ...staticParam, organization: param.organization });
      })
      .catch((err: any) => {});
  };

  const assignUser = () => {
    if (!id) {
      toast.error('Please select user', alertOptions);
      return;
    }
    updateData(
      showAssignee?.json?.id,
      {
        assignTo: [id],
      },
      MASTER_ENDPOINT.Queue + '/assign',
      true,
    )
      .then((resp: any) => {
        setshowAssignee({
          json: {},
          open: !showAssignee.open,
        });
        setId('');
        setParam({ ...staticParam, organization: param.organization });
      })
      .catch((err: any) => {});
  };

  const rowProps = (row: any, index: number) => {
    let empName =
      row?.leave?.user?.firstName + ' ' + row?.leave?.user?.lastName;
    let userId = row?.leave?.userId;
    if (
      row.type === 'newEmployee' ||
      row.type === 'projectEnd' ||
      row.type === 'piBufferPayoutRequest' ||
      row.type === 'payrollQuestion'
    ) {
      empName = row?.user?.firstName + ' ' + row?.user?.lastName;
      userId = row?.userId;
    }
    let status = '2 Days';
    if (
      row?.type === 'leaveRequest' ||
      row?.type === 'leaveReturn' ||
      row?.type === 'leaveChanges' ||
      row?.type === 'leaveCancel' ||
      row?.type === 'leaveUpdated' ||
      row?.type === 'leavePlanned'
    ) {
      const dateDiff = moment().diff(moment(row?.leave?.createdAt), 'days');
      if (dateDiff <= 4) {
        status =
          dateDiff === 0
            ? '5 Days'
            : dateDiff === 1
            ? '4 Days'
            : dateDiff === 2
            ? '3 Days'
            : dateDiff === 3
            ? '2 Days'
            : '1 Days';
      } else {
        status = 'PAST DUE';
      }
    }
    if (row?.type === 'projectEnd') {
      const dateDiff = moment(row?.projectConsultant?.endDate).diff(
        moment(),
        'days',
      );
      if (dateDiff > 0) {
        status = `${dateDiff} Days`;
      } else {
        status = 'PAST DUE';
      }
    }
    let payOutDates = '';
    let requestAmount = 0;
    if (row.type === 'piBufferPayoutRequest') {
      row?.piBufferRequest?.piBuffer?.map((row1: any) => {
        requestAmount += row1?.requestedBufferAmount
          ? parseFloat(row1?.requestedBufferAmount)
          : 0;
        if (row1?.payPeriod?.payDate) {
          if (payOutDates) {
            payOutDates +=
              ' and ' + moment(row1?.payPeriod?.payDate).format('MMM DD, YYYY');
          } else {
            payOutDates += moment(row1?.payPeriod?.payDate).format(
              'MMM DD, YYYY',
            );
          }
        }
      });
    }
    return (
      <StyledTableRow key={index}>
        <StyledTableCell align="center" style={{ verticalAlign: 'top' }}>
          <Typography
            style={{
              background:
                row.type === 'leaveReturn' ||
                row.type === 'leaveChanges' ||
                row.type === 'leaveCancel'
                  ? '#1D9900'
                  : row.type === 'newEmployee' || row.type === 'projectEnd'
                  ? '#E75000'
                  : '#BF0000',
              borderRadius: 10 / 2,
              height: 10,
              marginLeft: 15,
              marginTop: 5,
              width: 10,
            }}
          />
        </StyledTableCell>
        <StyledTableCell>
          <Typography
            component={'div'}
            sx={{
              color: '#292929',
              fontFamily: 'Red Hat Display Bold',
              fontSize: 15,
            }}>
            {row?.type === 'payrollQuestionNotification'
              ? 'The Payroll Exception for the below pay period is ready for review. Please add notes and submit.'
              : row?.title}
          </Typography>
          {row?.type !== 'payrollQuestionNotification' ? (
            row.type === 'piBufferPayoutRequest' ? (
              <Grid
                container
                sx={{
                  margin: 0,
                  marginBottom: '10px',
                  marginTop: '10px',
                }}>
                <Grid sm={4} sx={{ paddingBottom: '2px', paddingTop: '2px' }}>
                  <Typography
                    sx={{
                      fontFamily: 'Red Hat Display SemiBold',
                      fontSize: 12,
                    }}>
                    Employee
                  </Typography>
                </Grid>
                <Grid sm={8} sx={{ paddingBottom: '2px', paddingTop: '2px' }}>
                  <Typography
                    sx={{
                      fontFamily: 'Red Hat Display SemiBold',
                      fontSize: 12,
                    }}>
                    <NavLink
                      to={`${String(
                        AdminPrivateRoutes.CONSULTANTS,
                      )}/profile/${String(userId)}`}
                      style={{ color: 'inherit', textDecoration: 'inherit' }}>
                      {empName}
                    </NavLink>
                  </Typography>
                </Grid>
                <Grid sm={4} sx={{ paddingBottom: '2px', paddingTop: '2px' }}>
                  <Typography
                    sx={{
                      fontFamily: 'Red Hat Display SemiBold',
                      fontSize: 12,
                    }}>
                    Amount Requested
                  </Typography>
                </Grid>
                <Grid sm={8} sx={{ paddingBottom: '2px', paddingTop: '2px' }}>
                  <Typography
                    sx={{
                      fontFamily: 'Red Hat Display SemiBold',
                      fontSize: 12,
                    }}>
                    {roundOfData(requestAmount)}
                  </Typography>
                </Grid>
                <Grid sm={4} sx={{ paddingBottom: '2px', paddingTop: '2px' }}>
                  <Typography
                    sx={{
                      fontFamily: 'Red Hat Display SemiBold',
                      fontSize: 12,
                    }}>
                    Pay Out Dates
                  </Typography>
                </Grid>
                <Grid sm={8} sx={{ paddingBottom: '2px', paddingTop: '2px' }}>
                  <Typography
                    sx={{
                      fontFamily: 'Red Hat Display SemiBold',
                      fontSize: 12,
                    }}>
                    {payOutDates}
                  </Typography>
                </Grid>
                <Grid sm={4} sx={{ paddingBottom: '2px', paddingTop: '2px' }}>
                  <Typography
                    sx={{
                      fontFamily: 'Red Hat Display SemiBold',
                      fontSize: 12,
                    }}>
                    Amount Available
                  </Typography>
                </Grid>
                <Grid sm={8} sx={{ paddingBottom: '2px', paddingTop: '2px' }}>
                  <Typography
                    sx={{
                      fontFamily: 'Red Hat Display SemiBold',
                      fontSize: 12,
                    }}>
                    {roundOfData(row?.piBufferRequest?.availableBufferAmount)}
                  </Typography>
                </Grid>
                {userInfo.roleId !== 5 && (
                  <>
                    <Grid
                      sm={4}
                      sx={{ paddingBottom: '2px', paddingTop: '2px' }}>
                      <Typography
                        sx={{
                          fontFamily: 'Red Hat Display SemiBold',
                          fontSize: 12,
                        }}>
                        Details
                      </Typography>
                    </Grid>
                    <Grid
                      sm={8}
                      sx={{ paddingBottom: '2px', paddingTop: '2px' }}>
                      <Typography
                        component={'a'}
                        className={'pointer'}
                        sx={{
                          color: '#005EBF',
                          fontFamily: 'Red Hat Display SemiBold',
                          fontSize: 12,
                          textDecoration: 'underline',
                        }}
                        onClick={(e) => {
                          let paoytOption = 'approved';
                          row.piBufferRequest.piBuffer.map((item: any) => {
                            if (
                              parseFloat(item.approvedBufferAmount) !==
                              parseFloat(item.requestedBufferAmount)
                            ) {
                              paoytOption = 'adjsted';
                            }
                          });
                          setPiBuffer({
                            ...piBuffer,
                            comments: row?.comments,
                            item: row,
                            payout: paoytOption,
                            visible: true,
                          });
                        }}>
                        View
                      </Typography>
                    </Grid>
                  </>
                )}
              </Grid>
            ) : row.type === 'projectEnd' ? (
              <Grid
                container
                sx={{
                  margin: 0,
                  marginBottom: '10px',
                  marginTop: '10px',
                }}>
                <Grid sm={4} sx={{ paddingBottom: '2px', paddingTop: '2px' }}>
                  <Typography
                    sx={{
                      fontFamily: 'Red Hat Display SemiBold',
                      fontSize: 12,
                    }}>
                    Project
                  </Typography>
                </Grid>
                <Grid sm={8} sx={{ paddingBottom: '2px', paddingTop: '2px' }}>
                  <Typography
                    sx={{
                      fontFamily: 'Red Hat Display SemiBold',
                      fontSize: 12,
                    }}>
                    {row?.project?.project}
                  </Typography>
                </Grid>
                <Grid sm={4} sx={{ paddingBottom: '2px', paddingTop: '2px' }}>
                  <Typography
                    sx={{
                      fontFamily: 'Red Hat Display SemiBold',
                      fontSize: 12,
                    }}>
                    Start Date
                  </Typography>
                </Grid>
                <Grid sm={8} sx={{ paddingBottom: '2px', paddingTop: '2px' }}>
                  <Typography
                    sx={{
                      fontFamily: 'Red Hat Display SemiBold',
                      fontSize: 12,
                    }}>
                    {`${moment(row?.projectConsultant?.startDate).format(
                      'MMM DD, YYYY',
                    )} through ${moment(row?.projectConsultant?.endDate).format(
                      'MMM DD, YYYY',
                    )}`}
                  </Typography>
                </Grid>
                <Grid sm={4} sx={{ paddingBottom: '2px', paddingTop: '2px' }}>
                  <Typography
                    sx={{
                      fontFamily: 'Red Hat Display SemiBold',
                      fontSize: 12,
                    }}>
                    Consultants
                  </Typography>
                </Grid>
                <Grid sm={8} sx={{ paddingBottom: '2px', paddingTop: '2px' }}>
                  <Typography
                    sx={{
                      fontFamily: 'Red Hat Display SemiBold',
                      fontSize: 12,
                    }}>
                    <NavLink
                      to={`${String(
                        AdminPrivateRoutes.CONSULTANTS,
                      )}/profile/${String(userId)}`}
                      style={{ color: 'inherit', textDecoration: 'inherit' }}>
                      {empName}
                    </NavLink>
                  </Typography>
                </Grid>
                <Grid sm={4} sx={{ paddingBottom: '2px', paddingTop: '2px' }}>
                  <Typography
                    sx={{
                      fontFamily: 'Red Hat Display SemiBold',
                      fontSize: 12,
                    }}>
                    Details
                  </Typography>
                </Grid>
                <Grid sm={8} sx={{ paddingBottom: '2px', paddingTop: '2px' }}>
                  <Typography
                    sx={{
                      fontFamily: 'Red Hat Display SemiBold',
                      fontSize: 12,
                    }}>
                    {row?.projectConsultant?.ultraStaffDbName}
                  </Typography>
                </Grid>
                <Grid sm={4} sx={{ paddingBottom: '2px', paddingTop: '2px' }}>
                  <Typography
                    sx={{
                      fontFamily: 'Red Hat Display SemiBold',
                      fontSize: 12,
                    }}>
                    NLEd
                  </Typography>
                </Grid>
                <Grid sm={8} sx={{ paddingBottom: '2px', paddingTop: '2px' }}>
                  <Typography
                    sx={{
                      color: 'red',
                      fontFamily: 'Red Hat Display SemiBold',
                      fontSize: 12,
                    }}>
                    {row?.projectConsultant?.isEligibleForRehire === null
                      ? ''
                      : row?.projectConsultant?.isEligibleForRehire
                      ? 'YES'
                      : 'NO'}
                  </Typography>
                </Grid>
              </Grid>
            ) : row.type === 'payrollQuestion' ? (
              <Grid
                container
                sx={{
                  margin: 0,
                  marginBottom: '10px',
                  marginTop: '10px',
                }}>
                <Grid sm={6} sx={{ paddingBottom: '2px', paddingTop: '2px' }}>
                  <Typography
                    sx={{
                      fontFamily: 'Red Hat Display SemiBold',
                      fontSize: 12,
                    }}>
                    Employee
                  </Typography>
                </Grid>
                <Grid sm={6} sx={{ paddingBottom: '2px', paddingTop: '2px' }}>
                  <Typography
                    sx={{
                      fontFamily: 'Red Hat Display SemiBold',
                      fontSize: 12,
                    }}>
                    <NavLink
                      to={`${String(
                        AdminPrivateRoutes.CONSULTANTS,
                      )}/profile/${String(userId)}`}
                      style={{ color: 'inherit', textDecoration: 'inherit' }}>
                      {empName}
                    </NavLink>
                  </Typography>
                </Grid>
                <Grid sm={6} sx={{ paddingBottom: '2px', paddingTop: '2px' }}>
                  <Typography
                    sx={{
                      fontFamily: 'Red Hat Display SemiBold',
                      fontSize: 12,
                    }}>
                    Buffer Pending:
                  </Typography>
                </Grid>
                <Grid sm={6} sx={{ paddingBottom: '2px', paddingTop: '2px' }}>
                  <Typography
                    sx={{
                      fontFamily: 'Red Hat Display SemiBold',
                      fontSize: 12,
                    }}>
                    {roundOfData(row?.payrollQuestion?.currentOweAmount)}
                  </Typography>
                </Grid>
                <Grid sm={6} sx={{ paddingBottom: '2px', paddingTop: '2px' }}>
                  <Typography
                    sx={{
                      fontFamily: 'Red Hat Display SemiBold',
                      fontSize: 12,
                    }}>
                    Available To Pay:
                  </Typography>
                </Grid>
                <Grid sm={6} sx={{ paddingBottom: '2px', paddingTop: '2px' }}>
                  <Typography
                    sx={{
                      fontFamily: 'Red Hat Display SemiBold',
                      fontSize: 12,
                    }}>
                    {roundOfData(row?.payrollQuestion?.currentBufferAmount)}
                  </Typography>
                </Grid>
                {/* <Grid sm={6} sx={{ paddingBottom: '2px', paddingTop: '2px' }}>
                  <Typography
                    sx={{
                      fontFamily: 'Red Hat Display SemiBold',
                      fontSize: 12,
                    }}>
                    Balance:
                  </Typography>
                </Grid> */}
                {/* <Grid sm={6} sx={{ paddingBottom: '2px', paddingTop: '2px' }}>
                  <Typography
                    sx={{
                      fontFamily: 'Red Hat Display SemiBold',
                      fontSize: 12,
                    }}>
                    {Math.max(
                      row?.payrollQuestion?.previousBufferAmount
                        ? parseInt(row?.payrollQuestion?.previousBufferAmount)
                        : 0,
                      row?.payrollQuestion?.currentBufferAmount
                        ? parseInt(row?.payrollQuestion?.currentBufferAmount)
                        : 0,
                    ) -
                      Math.min(
                        row?.payrollQuestion?.previousBufferAmount
                          ? parseInt(row?.payrollQuestion?.previousBufferAmount)
                          : 0,
                        row?.payrollQuestion?.currentBufferAmount
                          ? parseInt(row?.payrollQuestion?.currentBufferAmount)
                          : 0,
                      )}
                  </Typography>
                </Grid> */}
                <Grid sm={6} sx={{ paddingBottom: '2px', paddingTop: '2px' }}>
                  <Typography
                    sx={{
                      fontFamily: 'Red Hat Display SemiBold',
                      fontSize: 12,
                    }}>
                    Notes:
                  </Typography>
                </Grid>
                <Grid sm={6} sx={{ paddingBottom: '2px', paddingTop: '2px' }}>
                  <Typography
                    sx={{
                      fontFamily: 'Red Hat Display SemiBold',
                      fontSize: 12,
                    }}>
                    {row?.payrollQuestion?.notes &&
                    row?.payrollQuestion?.notes.length
                      ? row?.payrollQuestion?.notes?.[0]?.note
                      : ''}
                  </Typography>
                </Grid>
              </Grid>
            ) : row.type === 'sickHoursRequest' ? (
              <Grid
                container
                sx={{
                  margin: 0,
                  marginBottom: '10px',
                  marginTop: '10px',
                }}>
                <Grid sm={4} sx={{ paddingBottom: '2px', paddingTop: '2px' }}>
                  <Typography
                    sx={{
                      fontFamily: 'Red Hat Display SemiBold',
                      fontSize: 12,
                    }}>
                    Employee
                  </Typography>
                </Grid>
                <Grid sm={8} sx={{ paddingBottom: '2px', paddingTop: '2px' }}>
                  <Typography
                    sx={{
                      fontFamily: 'Red Hat Display SemiBold',
                      fontSize: 12,
                    }}>
                    <NavLink
                      to={`${String(
                        AdminPrivateRoutes.CONSULTANTS,
                      )}/profile/${String(row.userId)}`}
                      style={{ color: 'inherit', textDecoration: 'inherit' }}>
                      {row.user.firstName + ' ' + row.user.lastName}
                    </NavLink>
                  </Typography>
                </Grid>

                <Grid sm={4} sx={{ paddingBottom: '2px', paddingTop: '2px' }}>
                  <Typography
                    sx={{
                      fontFamily: 'Red Hat Display SemiBold',
                      fontSize: 12,
                    }}>
                    Dates:
                  </Typography>
                </Grid>
                <Grid sm={8} sx={{ paddingBottom: '2px', paddingTop: '2px' }}>
                  <Typography
                    sx={{
                      fontFamily: 'Red Hat Display SemiBold',
                      fontSize: 12,
                    }}>
                    {moment(row?.outSick?.timesheetPeriod?.periodFrom).format(
                      'MMM DD,YYYY',
                    )}{' '}
                    to{' '}
                    {moment(row?.outSick?.timesheetPeriod?.periodTo).format(
                      'MMM DD,YYYY',
                    )}
                  </Typography>
                </Grid>
              </Grid>
            ) : (
              <Grid
                container
                sx={{
                  margin: 0,
                  marginBottom: '10px',
                  marginTop: '10px',
                }}>
                <Grid sm={4} sx={{ paddingBottom: '2px', paddingTop: '2px' }}>
                  <Typography
                    sx={{
                      fontFamily: 'Red Hat Display SemiBold',
                      fontSize: 12,
                    }}>
                    Employee
                  </Typography>
                </Grid>
                <Grid sm={8} sx={{ paddingBottom: '2px', paddingTop: '2px' }}>
                  <Typography
                    sx={{
                      fontFamily: 'Red Hat Display SemiBold',
                      fontSize: 12,
                    }}>
                    <NavLink
                      to={`${String(
                        AdminPrivateRoutes.CONSULTANTS,
                      )}/profile/${String(userId)}`}
                      style={{ color: 'inherit', textDecoration: 'inherit' }}>
                      {empName}
                    </NavLink>
                  </Typography>
                </Grid>
                {row.type !== 'newEmployee' && (
                  <>
                    <Grid
                      sm={4}
                      sx={{ paddingBottom: '2px', paddingTop: '2px' }}>
                      <Typography
                        sx={{
                          fontFamily: 'Red Hat Display SemiBold',
                          fontSize: 12,
                        }}>
                        Dates:
                      </Typography>
                    </Grid>
                    <Grid
                      sm={8}
                      sx={{ paddingBottom: '2px', paddingTop: '2px' }}>
                      <Typography
                        sx={{
                          fontFamily: 'Red Hat Display SemiBold',
                          fontSize: 12,
                        }}>
                        {moment(row?.leave?.fromDate).format('MMM DD,YYYY')} to{' '}
                        {moment(row?.leave?.toDate).format('MMM DD,YYYY')}
                      </Typography>
                    </Grid>
                  </>
                )}
                {row.type === 'leaveChanges' && (
                  <>
                    <Grid
                      sm={4}
                      sx={{ paddingBottom: '2px', paddingTop: '2px' }}>
                      <Typography
                        sx={{
                          fontFamily: 'Red Hat Display SemiBold',
                          fontSize: 12,
                        }}>
                        Comments:
                      </Typography>
                    </Grid>
                    <Grid
                      sm={8}
                      sx={{ paddingBottom: '2px', paddingTop: '2px' }}>
                      <Typography
                        component={'a'}
                        sx={{
                          fontFamily: 'Red Hat Display SemiBold',
                          fontSize: 12,
                        }}>
                        {row?.comments}
                      </Typography>
                    </Grid>
                  </>
                )}
                {row.type !== 'newEmployee' && row?.status === 'pending' && (
                  <>
                    <Grid
                      sm={4}
                      sx={{ paddingBottom: '2px', paddingTop: '2px' }}>
                      <Typography
                        sx={{
                          fontFamily: 'Red Hat Display SemiBold',
                          fontSize: 12,
                        }}>
                        Details:
                      </Typography>
                    </Grid>
                    <Grid
                      sm={8}
                      sx={{ paddingBottom: '2px', paddingTop: '2px' }}>
                      <Typography
                        component={'a'}
                        className={'pointer'}
                        sx={{
                          color: '#005EBF',
                          fontFamily: 'Red Hat Display SemiBold',
                          fontSize: 12,
                          textDecoration: 'underline',
                        }}
                        onClick={(e) => showLeaveInfo(row)}>
                        View
                      </Typography>
                      <Typography
                        component={'a'}
                        className={'pointer'}
                        sx={{
                          color: '#005EBF',
                          fontFamily: 'Red Hat Display SemiBold',
                          fontSize: 12,
                          paddingLeft: '10px',
                          textDecoration: 'underline',
                        }}
                        onClick={(e) => handlePdfDownload(row && row)}>
                        Download
                      </Typography>
                      {row?.leave?.leaveComments.length ? (
                        <Typography
                          component={'a'}
                          className={'pointer'}
                          sx={{
                            color: '#005EBF',
                            fontFamily: 'Red Hat Display SemiBold',
                            fontSize: 12,
                            paddingLeft: '10px',
                            textDecoration: 'underline',
                          }}
                          onClick={() =>
                            handleOpen1(row?.leave?.leaveComments)
                          }>
                          View Notes
                        </Typography>
                      ) : (
                        <Typography />
                      )}
                    </Grid>
                  </>
                )}
                {row.type === 'newEmployee' && (
                  <>
                    <Grid
                      sm={4}
                      sx={{ paddingBottom: '2px', paddingTop: '2px' }}>
                      <Typography
                        sx={{
                          fontFamily: 'Red Hat Display SemiBold',
                          fontSize: 12,
                        }}>
                        Email
                      </Typography>
                    </Grid>
                    <Grid
                      sm={8}
                      sx={{ paddingBottom: '2px', paddingTop: '2px' }}>
                      <Typography
                        sx={{
                          fontFamily: 'Red Hat Display SemiBold',
                          fontSize: 12,
                          wordBreak: 'break-all',
                        }}>
                        {row?.user?.alternativeEmail}
                      </Typography>
                    </Grid>
                    <Grid
                      sm={4}
                      sx={{ paddingBottom: '2px', paddingTop: '5px' }}>
                      <Typography
                        sx={{
                          fontFamily: 'Red Hat Display SemiBold',
                          fontSize: 12,
                        }}>
                        Details:
                      </Typography>
                    </Grid>
                    <Grid
                      sm={8}
                      sx={{ paddingBottom: '2px', paddingTop: '2px' }}>
                      <Typography
                        component={'a'}
                        className={'pointer'}
                        sx={{
                          color: '#005EBF',
                          fontFamily: 'Red Hat Display SemiBold',
                          fontSize: 12,
                          textDecoration: 'underline',
                        }}
                        onClick={(e) => showProjectInfo(row)}>
                        View
                      </Typography>
                    </Grid>
                    <Grid
                      sm={4}
                      sx={{ paddingBottom: '2px', paddingTop: '2px' }}>
                      <Typography
                        sx={{
                          fontFamily: 'Red Hat Display SemiBold',
                          fontSize: 12,
                        }}>
                        Status
                      </Typography>
                    </Grid>
                    <Grid
                      sm={8}
                      sx={{ paddingBottom: '2px', paddingTop: '2px' }}>
                      <Typography
                        sx={{
                          color:
                            row?.user?.consultantStatus === 'Active'
                              ? 'green'
                              : 'red',
                          fontFamily: 'Red Hat Display SemiBold',
                          fontSize: 12,
                          wordBreak: 'break-all',
                        }}>
                        {row?.user?.consultantStatus}
                      </Typography>
                    </Grid>
                  </>
                )}
              </Grid>
            )
          ) : (
            <Grid
              container
              sx={{
                margin: 0,
                marginBottom: '10px',
                marginTop: '10px',
              }}>
              <Grid sm={6} sx={{ paddingBottom: '2px', paddingTop: '2px' }}>
                <Typography
                  sx={{
                    fontFamily: 'Red Hat Display SemiBold',
                    fontSize: 12,
                  }}>
                  Pay Period Start Date:
                </Typography>
              </Grid>
              <Grid sm={6} sx={{ paddingBottom: '2px', paddingTop: '2px' }}>
                <Typography
                  sx={{
                    fontFamily: 'Red Hat Display SemiBold',
                    fontSize: 12,
                  }}>
                  {moment(row?.payPeriod?.startDate).format('MM/DD/YYYY')}
                </Typography>
              </Grid>
              <Grid sm={6} sx={{ paddingBottom: '2px', paddingTop: '2px' }}>
                <Typography
                  sx={{
                    fontFamily: 'Red Hat Display SemiBold',
                    fontSize: 12,
                  }}>
                  Pay Period End Date:
                </Typography>
              </Grid>
              <Grid sm={6} sx={{ paddingBottom: '2px', paddingTop: '2px' }}>
                <Typography
                  sx={{
                    fontFamily: 'Red Hat Display SemiBold',
                    fontSize: 12,
                  }}>
                  {moment(row?.payPeriod?.endDate).format('MM/DD/YYYY')}
                </Typography>
              </Grid>
              {/* <Grid sm={12} sx={{ paddingBottom: '2px', paddingTop: '2px' }}>
                <Typography
                  sx={{
                    fontFamily: 'Red Hat Display SemiBold',
                    fontSize: 12,
                  }}>
                  {row?.title}
                </Typography>
              </Grid> */}
            </Grid>
          )}
        </StyledTableCell>
        <StyledTableCell>
          <Chip
            style={{ height: '28px' }}
            label={status}
            sx={{
              '& .MuiChip-label': {
                color:
                  row.type === 'leaveReturn' ||
                  row.type === 'leaveChanges' ||
                  row.type === 'leaveCancel'
                    ? '#1D9900'
                    : row.type === 'newEmployee' || row.type === 'projectEnd'
                    ? '#E86B07'
                    : '#DB2426',
                fontFamily: 'Red Hat Display Bold',
                fontSize: 10,
                padding: '0 10px',
                textTransform: 'uppercase',
              },
              background:
                row.type === 'leaveReturn' ||
                row.type === 'leaveChanges' ||
                row.type === 'leaveCancel'
                  ? 'rgba(29, 153, 0, 10%)'
                  : row.type === 'newEmployee' || row.type === 'projectEnd'
                  ? 'rgba(232, 107, 7, 10%)'
                  : 'rgba(219, 36,38, 10%)',
              borderRight: '20px',
              letterSpacing: 1.83,
              padding: '10px',
            }}
          />
        </StyledTableCell>
        <StyledTableCell style={{ paddingTop: '35px' }}>
          <span>{moment(row?.createdAt).format('MM/DD/YYYY')}</span>
        </StyledTableCell>
        <StyledTableCell>
          <Typography
            component={'div'}
            sx={{
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-start',
            }}>
            {row?.queueAssignedTo.length > 0 && (
              <AvatarGroup
                total={row?.queueAssignedTo.length}
                sx={{
                  height: '32px',
                  width: '32px',
                }}>
                {row?.queueAssignedTo.map((item: any, index: number) => {
                  return (
                    <Avatar
                      key={index}
                      sx={{
                        height: '32px',
                        width: '32px',
                      }}
                      alt="Profile Img"
                      src={
                        item?.user?.imageId
                          ? `${process.env.REACT_APP_APIURL}${
                              MASTER_ENDPOINT.Files
                            }/${String(item?.user?.imageId)}`
                          : profileimg
                      }
                    />
                  );
                })}
              </AvatarGroup>
            )}
            <Button
              onClick={handleClick('bottom-end', row)}
              sx={{
                paddingRight: '20px',
              }}>
              <Typography
                component={'div'}
                sx={{
                  border: '1px dotted #979797',
                  borderRadius: '17px',
                  color: '#979797',
                  cursor: 'pointer',
                  fontSize: '27px',
                  height: '33px',
                  textAlign: 'center',
                  width: '33px',
                }}>
                +
              </Typography>
            </Button>
          </Typography>
        </StyledTableCell>
        <StyledTableCell
          sx={{ paddingRight: '15px', width: '350px' }}
          align="right">
          {row?.queueAssignedTo.length ? renderButtton(row) : <Typography />}
        </StyledTableCell>
      </StyledTableRow>
    );
  };

  const [notesJSON, setNotesJSON] = React.useState<any>({
    item: [],
    open: false,
  });
  const handleOpen1 = (row: any) => {
    setNotesJSON({
      item: row,
      open: true,
    });
  };
  const handleClose1 = () => {
    setNotesJSON({
      item: [],
      open: false,
    });
  };
  const biBufferClose = () => {
    setPiBuffer({
      comments: '',
      item: {},
      payout: 'approved',
      status: '',
      visible: false,
    });
  };
  const [payLoading, setPayLoading] = React.useState<boolean>(false);
  const submitPayOut = () => {
    const buffArray = [];
    piBuffer?.item?.piBufferRequest?.piBuffer?.map((item: any) => {
      buffArray.push({
        approvedBufferAmount: parseFloat(item.approvedBufferAmount),
        id: item.id,
      });
    });
    const postJson = {
      buffer: buffArray,
      comments: piBuffer.comments,
      paymentBufferRequestId: piBuffer?.item?.piBufferRequest?.id,
      status: piBuffer.status,
    };
    setPayLoading(true);
    updateData(
      piBuffer.item.id,
      postJson,
      MASTER_ENDPOINT.Queue + '/pi-buffer/status',
      true,
    )
      .then((resp: any) => {
        setParam({ ...staticParam, organization: param.organization });
        biBufferClose();
        setPayLoading(false);
      })
      .catch((err: any) => {
        setPayLoading(false);
      });
  };

  return (
    <LoadingOverlay active={loader} spinner text="loading...">
      <Typography component={'div'}>
        <Grid container className={classes.tableview}>
          <TableContainer>
            <InfinityScroll
              paramState={setParam}
              height={height - 80}
              scrollableTargetId={'Admins'}
              data={list}
              rowPorops={rowProps}
              headRow={headRow}
              count={total}
              columnCount={6}
            />
          </TableContainer>
          {showAssignee.open && (
            <Popper
              open={showAssignee.open}
              anchorEl={anchorEl}
              placement={placement}
              transition>
              {({ TransitionProps }) => (
                <Fade {...TransitionProps} timeout={350}>
                  <Paper className="model-widget">
                    <Typography
                      component={'div'}
                      sx={{
                        margin: '0px !important',
                        padding: '10px !important',
                        width: '466px',
                      }}
                      className="request-payment-alert">
                      <Typography
                        component="div"
                        sx={{
                          color: '#292929',
                          fontFamily: 'Red Hat Display Regular !important',
                          fontSize: '12px',
                          lineHeight: '24px',
                        }}>
                        Assign Queue Item
                      </Typography>
                      <Typography component="div" sx={{ paddingTop: '5px' }}>
                        <Select
                          style={{ textTransform: 'capitalize' }}
                          className="common-select"
                          value={id}
                          onChange={(e: any) => {
                            setId(e?.target?.value);
                          }}
                          displayEmpty
                          inputProps={{ 'aria-label': 'Without label' }}>
                          <MenuItem
                            value={''}
                            style={{ textTransform: 'capitalize' }}>
                            Select
                          </MenuItem>
                          {userList.map((row: any, index: number) => {
                            return (
                              <MenuItem
                                key={index}
                                value={row.id}
                                style={{ textTransform: 'capitalize' }}>
                                {`${String(row?.firstName)} ${String(
                                  row?.lastName,
                                )}`}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </Typography>
                      <Typography
                        className="model-btn"
                        component={'div'}
                        sx={{
                          justifyContent: 'unset !important',
                          paddingBottom: '10px',
                          paddingTop: '15px',
                        }}>
                        <Typography
                          component={'div'}
                          sx={{
                            width: '25%',
                          }}>
                          <Button
                            variant="text"
                            onClick={() => {
                              setshowAssignee({
                                json: {},
                                open: false,
                              });
                              setId('');
                            }}>
                            Cancel
                          </Button>
                        </Typography>
                        <Typography
                          component={'div'}
                          sx={{
                            textAlign: 'end',
                            width: '75%',
                          }}>
                          {!_.isEmpty(showAssignee.json) &&
                            showAssignee.json.queueAssignedTo &&
                            showAssignee.json.queueAssignedTo.length === 0 && (
                              <Button variant="contained" onClick={assignToMe}>
                                Assign to Me
                              </Button>
                            )}
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          <Button variant="contained" onClick={assignUser}>
                            {!_.isEmpty(showAssignee.json) &&
                            showAssignee.json.queueAssignedTo &&
                            showAssignee.json.queueAssignedTo.length > 0
                              ? 'Re-Assign'
                              : 'Assign'}
                          </Button>
                        </Typography>
                      </Typography>
                    </Typography>
                  </Paper>
                </Fade>
              )}
            </Popper>
          )}
        </Grid>
        <Modal
          open={pQuestion.open}
          onClose={pQuestionClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description">
          <Box
            sx={pQuestionStyle}
            className={
              pQuestion.status !== 'approved'
                ? 'editmodal'
                : 'confirmation-widget'
            }>
            {pQuestion.status === 'approved' ? (
              <>
                <Typography id="modal-modal-title" component="h2">
                  Are you sure you want to Approve?
                </Typography>
                <Typography
                  className="model-btn"
                  component={'div'}
                  columnGap={2.5}
                  sx={{ justifyContent: 'center' }}>
                  <Button variant="outlined" onClick={pQuestionClose}>
                    No, Cancel
                  </Button>
                  <Button
                    variant="contained"
                    onClick={pQuestionApproveAndDenied}>
                    YES, Approve
                  </Button>
                </Typography>
              </>
            ) : (
              <>
                <Typography className="title">EDIT PAY PERIOD WAGE</Typography>
                <Grid container columnSpacing={1.5} className="c-bottom">
                  <Grid item sm={6}>
                    <Typography className="p-text">Buffer Pending</Typography>
                  </Grid>
                  <Grid item sm={3}>
                    <Typography className="p-text">Available to Pay</Typography>
                  </Grid>
                  <Grid item sm={3} />
                  <Grid item sm={6}>
                    <Typography className="pro-name">
                      {roundOfData(
                        pQuestion?.data?.payrollQuestion?.currentOweAmount,
                      )}
                    </Typography>
                  </Grid>
                  <Grid item sm={3}>
                    <Typography className="pro-name">
                      {roundOfData(
                        pQuestion?.data?.payrollQuestion?.currentBufferAmount,
                      )}
                    </Typography>
                  </Grid>
                  <Grid item sm={3} />
                  <Grid item sm={6}>
                    <Typography className="p-text">Reg Earning</Typography>
                  </Grid>
                  <Grid item sm={3}>
                    <Typography className="p-text">Adjusted Earning</Typography>
                  </Grid>
                  <Grid item sm={3} />
                  <Grid item sm={6}>
                    <Typography className="pro-name">
                      {roundOfData(pQuestion?.payrollData?.summary_regEarnings)}
                    </Typography>
                  </Grid>
                  <Grid item sm={3}>
                    <Typography className="p-text">
                      <CurrencyInput
                        id="input-example"
                        prefix={'$'}
                        placeholder={''}
                        className={'width-100'}
                        defaultValue={pQuestion?.adjustedAmount}
                        allowDecimals={true}
                        value={pQuestion?.adjustedAmount}
                        onValueChange={(e: any) => {
                          setPQuestion({
                            ...pQuestion,
                            adjustedAmount: e,
                          });
                        }}
                      />
                    </Typography>
                  </Grid>
                  <Grid item sm={3} />
                  <Grid item sm={6}>
                    <Typography className="p-text">PBN Amount</Typography>
                  </Grid>
                  <Grid item md={3}>
                    <Typography className="p-text">Adjusted PBN</Typography>
                  </Grid>
                  <Grid item sm={3} />
                  <Grid
                    container
                    columnSpacing={1.5}
                    sx={{ paddingLeft: '15px !important' }}>
                    <Grid item sm={6}>
                      <Typography className="pro-name">
                        {roundOfData(
                          pQuestion?.payrollData
                            ?.summary_currentEarnings3Amount1,
                        )}
                      </Typography>
                    </Grid>
                    <Grid item sm={3}>
                      <Typography className="p-text">
                        <CurrencyInput
                          id="input-example"
                          prefix={'$'}
                          placeholder={''}
                          className={'width-100'}
                          defaultValue={pQuestion?.currentEarnings3Amount1}
                          allowDecimals={true}
                          value={pQuestion?.currentEarnings3Amount1}
                          onValueChange={(e: any) => {
                            setPQuestion({
                              ...pQuestion,
                              currentEarnings3Amount1: e,
                            });
                          }}
                        />
                      </Typography>
                    </Grid>
                    <Grid item sm={3} />
                    <Grid item sm={12}>
                      <Typography className={'notesTitle'} component="h2">
                        Add Notes
                      </Typography>
                    </Grid>
                    <Grid item sm={12}>
                      <CustomInput
                        className="common-textarea"
                        id="outlined-multiline-static"
                        isTextArea={true}
                        minRows={3}
                        type={'text'}
                        placeHolder={'add notes'}
                        value={pQuestion.notes}
                        onChange={(e: any) => {
                          setPQuestion({
                            ...pQuestion,
                            notes: e.target.value,
                          });
                        }}
                        extraStyle={{
                          width: '100%',
                        }}
                        extraInputBaseStyle={{
                          margin: '0px !important',
                          padding: '0px !important',
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Typography className="model-btn" component={'div'}>
                  <Button variant="text" onClick={pQuestionClose}>
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    onClick={pQuestionApproveAndDenied}>
                    Submit Adjusted Amount
                  </Button>
                </Typography>
              </>
            )}
            {/* <Grid container>
              <Grid item xs={10}>
                <Typography id="modal-modal-title" component="h2">
                  Projects
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography
                  component={'div'}
                  sx={{
                    textAlign: 'right',
                  }}>
                  <a
                    href={'#'}
                    onClick={projectInfoClose}
                    style={leaveStyle.closeText}>
                    Close
                  </a>
                </Typography>
              </Grid>
            </Grid> */}
            {/* <Grid container sx={{ paddingBottom: '20px', paddingTop: '20px' }}>
              <Grid item md={12} sx={{ width: '100%' }}>
                <TableContainer>
                  <Table aria-label="customized table">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell
                          style={{ paddingLeft: '10px', width: '30%' }}>
                          Projects
                        </StyledTableCell>
                        <StyledTableCell style={{ width: 100 }}>
                          Spec Start
                        </StyledTableCell>
                        <StyledTableCell style={{ width: 50 }}>
                          Bill to:
                        </StyledTableCell>
                        <StyledTableCell style={{ width: 100 }}>
                          P/E
                        </StyledTableCell>
                        <StyledTableCell>End Client</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {projectInfo?.consultantProjects?.length ? (
                        projectInfo?.consultantProjects.map(
                          (row: any, index: number) => (
                            <StyledTableRow key={row.index}>
                              <StyledTableCell>
                                {row?.project?.project}
                              </StyledTableCell>
                              <StyledTableCell>
                                {moment(row.startDate).format('MM-DD-YYYY')}
                              </StyledTableCell>
                              <StyledTableCell>{row.branch}</StyledTableCell>
                              <StyledTableCell>
                                {moment(row.endDate).format('MM-DD-YYYY')}
                              </StyledTableCell>
                              <StyledTableCell>
                                {row?.client?.clientName}
                              </StyledTableCell>
                            </StyledTableRow>
                          ),
                        )
                      ) : (
                        <StyledTableRow>
                          {projectLoader ? (
                            <Typography />
                          ) : (
                            <StyledTableCell
                              colSpan={5}
                              sx={{
                                color: '#241a2e',
                                fontFamily: 'Red Hat Display SemiBold',
                                fontSize: '14px',
                                textAlign: 'center',
                              }}>
                              No Records Found
                            </StyledTableCell>
                          )}
                        </StyledTableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
                {projectLoader && (
                  <Typography
                    component={'div'}
                    sx={{
                      justifyContent: 'center',
                      paddingTop: '20px',
                      textAlign: 'center',
                    }}>
                    <CircularProgress sx={{ color: '#340000' }} />
                  </Typography>
                )}
              </Grid>
            </Grid> */}
          </Box>
        </Modal>
        <Modal
          open={projectInfoModel}
          onClose={projectInfoClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description">
          <Box sx={projectStyle} className="model-widget">
            <Grid container>
              <Grid item xs={10}>
                <Typography id="modal-modal-title" component="h2">
                  Projects
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography
                  component={'div'}
                  sx={{
                    textAlign: 'right',
                  }}>
                  <a
                    href={'#'}
                    onClick={projectInfoClose}
                    style={leaveStyle.closeText}>
                    Close
                  </a>
                </Typography>
              </Grid>
            </Grid>
            <Grid container sx={{ paddingBottom: '20px', paddingTop: '20px' }}>
              <Grid item md={12} sx={{ width: '100%' }}>
                <TableContainer>
                  <Table aria-label="customized table">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell
                          style={{ paddingLeft: '10px', width: '30%' }}>
                          Projects
                        </StyledTableCell>
                        <StyledTableCell style={{ width: 100 }}>
                          Spec Start
                        </StyledTableCell>
                        <StyledTableCell style={{ width: 50 }}>
                          Bill to:
                        </StyledTableCell>
                        <StyledTableCell style={{ width: 100 }}>
                          P/E
                        </StyledTableCell>
                        <StyledTableCell>End Client</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {projectInfo?.consultantProjects?.length ? (
                        projectInfo?.consultantProjects.map(
                          (row: any, index: number) => (
                            <StyledTableRow key={row.index}>
                              <StyledTableCell>
                                {row?.project?.project}
                              </StyledTableCell>
                              <StyledTableCell>
                                {moment(row.startDate).format('MM-DD-YYYY')}
                              </StyledTableCell>
                              <StyledTableCell>{row.branch}</StyledTableCell>
                              <StyledTableCell>
                                {moment(row.endDate).format('MM-DD-YYYY')}
                              </StyledTableCell>
                              <StyledTableCell>
                                {row?.client?.clientName}
                              </StyledTableCell>
                            </StyledTableRow>
                          ),
                        )
                      ) : (
                        <StyledTableRow>
                          {projectLoader ? (
                            <Typography />
                          ) : (
                            <StyledTableCell
                              colSpan={5}
                              sx={{
                                color: '#241a2e',
                                fontFamily: 'Red Hat Display SemiBold',
                                fontSize: '14px',
                                textAlign: 'center',
                              }}>
                              No Records Found
                            </StyledTableCell>
                          )}
                        </StyledTableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
                {projectLoader && (
                  <Typography
                    component={'div'}
                    sx={{
                      justifyContent: 'center',
                      paddingTop: '20px',
                      textAlign: 'center',
                    }}>
                    <CircularProgress sx={{ color: '#340000' }} />
                  </Typography>
                )}
              </Grid>
            </Grid>
          </Box>
        </Modal>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description">
          <Box sx={style} className="model-widget">
            <Typography id="modal-modal-title" component="h2">
              {editId.type === 'newEmployee' && userInfo.roleId === 4
                ? 'Update Email'
                : 'Notes'}
            </Typography>
            <Grid container alignItems="center" sx={{ paddingTop: '10px' }}>
              <Grid sm={12}>
                {editId.type === 'projectEnd' &&
                  editId.status === 'extended' &&
                  userInfo.roleId === 5 && (
                    <Typography
                      sx={{
                        color: '#292929',
                        fontFamily: 'Red Hat Display SemiBold',
                        fontSize: '14px',
                        paddingBottom: '5px',
                        paddingTop: '5px',
                      }}>
                      {
                        'Please Make sure the new End Date is updated in Ultrastaff'
                      }
                    </Typography>
                  )}
                {editId.type === 'projectEnd' &&
                  editId.status === 'confirmed' &&
                  userInfo.roleId === 5 && (
                    <>
                      <Typography
                        sx={{
                          color: '#292929',
                          fontFamily: 'Red Hat Display SemiBold',
                          fontSize: '14px',
                          lineHeight: '23px',
                          paddingBottom: '5px',
                          paddingTop: '5px',
                        }}>
                        {
                          'This is the currently only project assigned to this consultant. Upon project end, will this employee be NLE (No longer employed)?'
                        }
                        <CheckboxCom
                          checkboxArray={[
                            { label: 'Yes', value: 'yes' },
                            { label: 'No', value: 'no' },
                          ]}
                          onChange={(e) => {
                            setEditId({
                              ...editId,
                              checkboxStatus: e,
                            });
                          }}
                          value={editId?.checkboxStatus}
                        />
                      </Typography>
                      {editId?.checkboxStatus === 'yes' && (
                        <Typography
                          sx={{
                            color: '#292929',
                            fontFamily: 'Red Hat Display SemiBold',
                            fontSize: '14px',
                            lineHeight: '23px',
                            paddingBottom: '5px',
                            paddingTop: '5px',
                          }}>
                          {'If yes, are they eligible for rehire?'}
                          <CheckboxCom
                            checkboxArray={[
                              { label: 'Yes', value: 'yes' },
                              { label: 'No', value: 'no' },
                            ]}
                            onChange={(e) => {
                              setEditId({
                                ...editId,
                                ifYesCheckboxStatus: e,
                              });
                            }}
                            value={editId?.ifYesCheckboxStatus}
                          />
                        </Typography>
                      )}
                    </>
                  )}
                {editId.type === 'newEmployee' && userInfo.roleId === 4 && (
                  <Grid container>
                    <Grid sm={12} sx={{ paddingRight: '10px' }}>
                      <Typography
                        sx={{
                          color: '#292929',
                          fontFamily: 'Red Hat Display Regular',
                          fontSize: '12px',
                          paddingBottom: '5px',
                        }}>
                        {
                          "Please enter emplolyee's work email for login purpose"
                        }
                      </Typography>
                      <CustomInput
                        placeHolder="Email address"
                        value={editId.email}
                        className={classes.custominput}
                        onChange={(e: any) => {
                          setEditId({
                            ...editId,
                            email: e.target.value,
                          });
                        }}
                      />
                    </Grid>
                  </Grid>
                )}
                {editId.status === 'extended' && userInfo.roleId === 6 && (
                  <Grid container>
                    <Grid sm={6} sx={{ paddingRight: '10px' }}>
                      <Typography
                        sx={{
                          color: '#292929',
                          fontFamily: 'Red Hat Display Regular',
                          fontSize: '12px',
                          paddingBottom: '5px',
                        }}>
                        Extend Date
                      </Typography>
                      <DatePicker
                        placeHolder="Select"
                        value={extendedTo}
                        name={'extended'}
                        onChange={(e: any) => {
                          setExtendedTo(e);
                        }}
                        minDate={moment()}
                      />
                    </Grid>
                    <Grid sm={6}>
                      <Typography
                        sx={{
                          color: '#292929',
                          fontFamily: 'Red Hat Display Regular',
                          fontSize: '12px',
                          paddingBottom: '5px',
                        }}>
                        Return Date
                      </Typography>
                      <DatePicker
                        placeHolder="Select"
                        value={returnTo}
                        name={'extended'}
                        onChange={(e: any) => {
                          setReturnTo(e);
                        }}
                        minDate={moment()}
                      />
                    </Grid>
                  </Grid>
                )}
                <Typography component="div" sx={{ paddingTop: '10px' }}>
                  <TextareaAutosize
                    maxRows={4}
                    aria-label="maximum height"
                    placeholder="Add Notes"
                    style={{ width: '100%' }}
                    value={note}
                    onChange={(e) => setNote(e.target.value)}
                  />
                </Typography>
              </Grid>
            </Grid>
            <Typography component={'div'} sx={{ paddingTop: '20px' }} />
            <Divider />
            <Typography component={'div'} sx={{ paddingTop: '20px' }} />
            <Typography className="model-btn" component={'div'}>
              <Button variant="text" onClick={handleClose}>
                Cancel
              </Button>
              <CustomButton
                variant="contained"
                isStyle={true}
                name={'Submit'}
                form={'changePassword'}
                loading={loading}
                onPress={() => {
                  submitNote();
                }}
              />
            </Typography>
          </Box>
        </Modal>
        <Modal
          open={notesJSON.open}
          onClose={handleClose1}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description">
          <Box sx={style} className="model-widget">
            <Typography id="modal-modal-title" component="h2">
              Notes History
            </Typography>
            <Grid container alignItems="center">
              {notesJSON.item.map((row: any, index: number) => {
                return (
                  <Grid
                    key={index}
                    sm={12}
                    sx={{
                      borderBottom: '0.5px solid #eeeeee',
                      paddingBottom: '10px',
                      paddingTop: '10px',
                    }}>
                    <Typography
                      component="div"
                      sx={{
                        fontFamily: 'Red Hat Display Bold',
                        fontSize: 14,
                      }}>
                      {row?.user?.firstName}, {row?.user?.lastName}{' '}
                      <Typography
                        component="span"
                        sx={{
                          fontFamily: 'Red Hat Display Regular !important',
                          fontSize: '12px !important',
                        }}>
                        ({moment(row?.createdAt).format('MM-DD-YYYY')})
                      </Typography>
                    </Typography>
                    <Typography
                      component="div"
                      sx={{
                        fontFamily: 'Red Hat Display Regular !important',
                        fontSize: 14,
                        paddingTop: '10px',
                      }}>
                      {row.comments}
                    </Typography>
                  </Grid>
                );
              })}
            </Grid>
          </Box>
        </Modal>
        <Modal
          open={openLeaveApplicationModel}
          onClose={handleCloseLeaveApplicationModel}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description">
          <form id={'leaveApply'}>
            <Box sx={styleLeaveApplication} className="model-widget">
              <Grid container>
                <Grid item xs={10}>
                  <Typography id="modal-modal-title" component="h2">
                    Leave Request Form
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography
                    component={'div'}
                    sx={{
                      textAlign: 'right',
                    }}>
                    <a
                      href={'#'}
                      onClick={resetForm}
                      style={leaveStyle.closeText}>
                      Close
                    </a>
                  </Typography>
                </Grid>
              </Grid>
              <Grid container sx={{ paddingTop: '10px' }}>
                <Grid item xs={3} sx={{ padding: '10px', paddingLeft: '0px' }}>
                  <Typography sx={leaveStyle.normalText}>
                    First Day of Leave<span className="errorText">{'*'}</span>
                  </Typography>
                  <Typography component={'div'} sx={{ paddingTop: '8px' }}>
                    <Controller
                      control={control}
                      defaultValue=""
                      name="fromDate"
                      render={({ field: { onChange, value, name, ref } }) => {
                        return (
                          <DatePicker
                            placeHolder="Select"
                            value={value}
                            disabled={true}
                            name={name}
                            onChange={(e: any) => {
                              onChange(e);
                            }}
                            error={formState.errors?.fromDate?.message}
                          />
                        );
                      }}
                    />
                  </Typography>
                </Grid>
                <Grid item xs={3} sx={{ padding: '10px', paddingLeft: '0px' }}>
                  <Typography sx={leaveStyle.normalText}>
                    Last Day of Leave<span className="errorText">{'*'}</span>
                  </Typography>
                  <Typography component={'div'} sx={{ paddingTop: '8px' }}>
                    <Controller
                      control={control}
                      defaultValue=""
                      name="toDate"
                      render={({ field: { onChange, value, name, ref } }) => {
                        return (
                          <DatePicker
                            placeHolder="Select"
                            value={value}
                            name={name}
                            onChange={(e: any) => {
                              onChange(e);
                            }}
                            disabled={true}
                            error={formState.errors?.toDate?.message}
                          />
                        );
                      }}
                    />
                  </Typography>
                </Grid>
                <Grid item xs={3} sx={{ padding: '10px', paddingLeft: '0px' }}>
                  <Typography sx={leaveStyle.normalText}>
                    Exact Return to Work Date
                    <span className="errorText">{'*'}</span>
                  </Typography>
                  <Typography component={'div'} sx={{ paddingTop: '8px' }}>
                    <Controller
                      control={control}
                      defaultValue=""
                      name="returnDate"
                      render={({ field: { onChange, value, name, ref } }) => {
                        return (
                          <DatePicker
                            placeHolder="Select"
                            value={value}
                            name={name}
                            onChange={(e: any) => {
                              onChange(e);
                            }}
                            error={formState.errors?.returnDate?.message}
                            disabled={true}
                          />
                        );
                      }}
                    />
                  </Typography>
                </Grid>
              </Grid>
              <Grid container sx={{ alignItems: 'center', paddingTop: '8px' }}>
                <Grid item xs={5}>
                  <Typography sx={leaveStyle.mediumText}>
                    Will you be traveling outside the U.S.?
                    <span className="errorText">{'*'}</span>
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <Controller
                    control={control}
                    defaultValue=""
                    name="travelingOutsideUS"
                    render={({ field: { onChange, value, name } }) => {
                      return (
                        <CheckboxCom
                          name={name}
                          checkboxArray={[
                            { label: 'Yes', value: 'yes' },
                            { label: 'No', value: 'no' },
                          ]}
                          onChange={(e) => {
                            if (e === 'no') {
                              setValue('returnToUsOn', null);
                            }
                            onChange(e);
                          }}
                          value={value}
                          disabled={true}
                          error={formState.errors?.travelingOutsideUS?.message}
                        />
                      );
                    }}
                  />
                </Grid>
              </Grid>
              {watch('travelingOutsideUS') === 'yes' && (
                <Grid container sx={{ paddingLeft: '20px' }}>
                  <Grid item xs={12}>
                    <Typography sx={leaveStyle.normalText}>
                      Enter date of return/re-entry to the U.S.
                    </Typography>
                  </Grid>
                  <Grid item xs={3} sx={{ paddingRight: '10px' }}>
                    <Typography sx={{ paddingTop: '5px' }}>
                      <Controller
                        control={control}
                        defaultValue=""
                        name="returnToUsOn"
                        render={({ field: { onChange, value, name, ref } }) => {
                          return (
                            <DatePicker
                              placeHolder="Select"
                              value={value}
                              name={name}
                              onChange={(e: any) => {
                                onChange(e);
                              }}
                              error={formState.errors?.returnToUsOn?.message}
                              disabled={true}
                            />
                          );
                        }}
                      />
                    </Typography>
                  </Grid>
                </Grid>
              )}
              <Grid container sx={{ alignItems: 'center', paddingTop: '8px' }}>
                <Grid item xs={5}>
                  <Typography sx={leaveStyle.mediumText}>
                    Will you be Working While on Leave?
                    <span className="errorText">{'*'}</span>
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <Controller
                    control={control}
                    defaultValue=""
                    name="workingWhileOnLeave"
                    render={({ field: { onChange, value, name } }) => {
                      return (
                        <CheckboxCom
                          name={name}
                          checkboxArray={[
                            { label: 'Yes', value: 'yes' },
                            { label: 'No', value: 'no' },
                          ]}
                          onChange={(e) => {
                            // if (e === 'no') {
                            //   setValue('workingFromDate', null);
                            //   setValue('workingToDate', null);
                            // }
                            onChange(e);
                          }}
                          disabled={true}
                          value={value}
                          error={formState.errors?.workingWhileOnLeave?.message}
                        />
                      );
                    }}
                  />
                </Grid>
              </Grid>
              {watch('workingWhileOnLeave') === 'yes' && (
                <Grid container sx={{ paddingLeft: '20px' }}>
                  <Grid item xs={12}>
                    <Typography sx={leaveStyle.normalText}>
                      If yes, please indicate the dates you will be working
                    </Typography>
                  </Grid>
                  {workingDaysArray.length &&
                    workingDaysArray.map((item: any, index: number) => {
                      return (
                        <Grid container key={index}>
                          <Grid item xs={3} sx={{ paddingRight: '10px' }}>
                            <Typography sx={{ paddingTop: '5px' }}>
                              <Controller
                                control={control}
                                defaultValue=""
                                name={`workingDays.${index}.fromDate`}
                                render={({
                                  field: { onChange, value, name, ref },
                                }) => {
                                  return (
                                    <DatePicker
                                      placeHolder="Select"
                                      value={value}
                                      name={name}
                                      onChange={(e: any) => {
                                        onChange(e);
                                      }}
                                      error={
                                        formState.errors?.workingDays?.[index]
                                          ?.fromDate?.message
                                      }
                                      disabled={true}
                                    />
                                  );
                                }}
                              />
                            </Typography>
                          </Grid>
                          <Grid item xs={3}>
                            <Typography sx={{ paddingTop: '5px' }}>
                              <Controller
                                control={control}
                                defaultValue=""
                                name={`workingDays.${index}.toDate`}
                                render={({
                                  field: { onChange, value, name, ref },
                                }) => {
                                  return (
                                    <DatePicker
                                      placeHolder="Select"
                                      value={value}
                                      name={name}
                                      onChange={(e: any) => {
                                        onChange(e);
                                      }}
                                      error={
                                        formState.errors?.workingDays?.[index]
                                          ?.toDate?.message
                                      }
                                      disabled={true}
                                    />
                                  );
                                }}
                              />
                            </Typography>
                          </Grid>
                        </Grid>
                      );
                    })}
                </Grid>
              )}
              <Grid container sx={{ alignItems: 'center', paddingTop: '8px' }}>
                <Grid item xs={5}>
                  <Typography sx={leaveStyle.mediumText}>
                    Do You Wish to be Paid While on Leave?
                    <span className="errorText">{'*'}</span>
                  </Typography>
                  <Typography
                    sx={{
                      color: '#BF0000',
                      fontFamily: 'Red Hat Display Regular',
                      fontSize: '12px',
                      paddingTop: '5px',
                    }}>
                    (must have approval from your supervisor and Payroll)
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <Controller
                    control={control}
                    defaultValue=""
                    name="wishToBePaidOnLeave"
                    render={({ field: { onChange, value, name } }) => {
                      return (
                        <CheckboxCom
                          name={name}
                          checkboxArray={[
                            { label: 'Yes', value: 'yes' },
                            { label: 'No', value: 'no' },
                            { label: 'Partially Paid', value: 'partialyPaid' },
                          ]}
                          onChange={(e) => onChange(e)}
                          value={value}
                          disabled={true}
                          error={formState.errors?.wishToBePaidOnLeave?.message}
                        />
                      );
                    }}
                  />
                </Grid>
              </Grid>
              {(watch('wishToBePaidOnLeave') === 'yes' ||
                watch('wishToBePaidOnLeave') === 'partialyPaid') && (
                <Grid container sx={{ paddingLeft: '20px' }}>
                  {payableDaysArray.length &&
                    payableDaysArray.map((item: any, index: number) => {
                      return (
                        <Grid container key={index}>
                          <Grid item xs={3} sx={{ paddingRight: '10px' }}>
                            <Typography sx={{ paddingTop: '5px' }}>
                              <Controller
                                control={control}
                                defaultValue=""
                                name={`payableDays.${index}.fromDate`}
                                render={({
                                  field: { onChange, value, name, ref },
                                }) => {
                                  return (
                                    <DatePicker
                                      placeHolder="Select"
                                      value={value}
                                      name={name}
                                      onChange={(e: any) => {
                                        onChange(e);
                                      }}
                                      error={
                                        formState.errors?.payableDays?.[index]
                                          ?.fromDate?.message
                                      }
                                      disabled={true}
                                    />
                                  );
                                }}
                              />
                            </Typography>
                          </Grid>
                          <Grid item xs={3}>
                            <Typography sx={{ paddingTop: '5px' }}>
                              <Controller
                                control={control}
                                defaultValue=""
                                name={`payableDays.${index}.toDate`}
                                render={({
                                  field: { onChange, value, name, ref },
                                }) => {
                                  return (
                                    <DatePicker
                                      placeHolder="Select"
                                      value={value}
                                      name={name}
                                      onChange={(e: any) => {
                                        onChange(e);
                                      }}
                                      error={
                                        formState.errors?.payableDays?.[index]
                                          ?.toDate?.message
                                      }
                                      disabled={true}
                                    />
                                  );
                                }}
                              />
                            </Typography>
                          </Grid>
                        </Grid>
                      );
                    })}
                </Grid>
              )}
              <Grid container sx={{ alignItems: 'center', paddingTop: '20px' }}>
                <Grid item xs={12}>
                  <Typography sx={leaveStyle.mediumText}>
                    Reason for Leave?<span className="errorText">{'*'}</span>
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    control={control}
                    defaultValue=""
                    name="leaveTypes"
                    render={({ field: { onChange, value, name } }) => {
                      return (
                        <CheckboxCom
                          name={name}
                          checkboxArray={leaveTypes}
                          onChange={(e) => onChange(e)}
                          multi={true}
                          value={value}
                          disabled={true}
                          error={formState.errors?.leaveTypes?.message}
                        />
                      );
                    }}
                  />
                </Grid>
                {watch('leaveTypes') &&
                  watch('leaveTypes').map((item: any, index: number) => {
                    if (Number(item.leaveTypeId) === 5) {
                      return (
                        <Grid
                          key={index}
                          item
                          xs={12}
                          style={{ paddingBottom: '5px', paddingTop: '5px' }}>
                          <Controller
                            control={control}
                            defaultValue=""
                            name="reasonComments"
                            render={({
                              field: { onChange, value, name, ref },
                            }) => {
                              return (
                                <CustomInput
                                  placeHolder="please explain"
                                  value={value}
                                  name={name}
                                  className={classes.custominput}
                                  ref={ref}
                                  onChange={(e: any) => {
                                    onChange(e);
                                  }}
                                  disabled={true}
                                  error={
                                    formState.errors?.reasonComments?.message
                                  }
                                />
                              );
                            }}
                          />
                        </Grid>
                      );
                    }
                    return;
                  })}
              </Grid>
              <Divider />
              <Grid container sx={{ paddingTop: '20px' }}>
                <Grid item xs={6} sx={{ paddingRight: '10px' }}>
                  <Typography sx={leaveStyle.normalText}>
                    Country of Leave
                  </Typography>
                  <Typography
                    component={'div'}
                    sx={{
                      padding: '10px',
                      paddingLeft: '0px',
                    }}>
                    <Controller
                      control={control}
                      defaultValue=""
                      name="onLeaveCountry"
                      render={({ field: { onChange, value, name, ref } }) => {
                        return (
                          <CustomInput
                            placeHolder="Country of Leave"
                            value={value}
                            name={name}
                            className={classes.custominput}
                            ref={ref}
                            onChange={(e: any) => {
                              onChange(e);
                            }}
                            disabled={true}
                            error={formState.errors?.onLeaveCountry?.message}
                          />
                        );
                      }}
                    />
                  </Typography>
                  <Typography sx={leaveStyle.normalText}>
                    Preferred Method of Communication While on Leave
                  </Typography>
                  <Typography
                    component={'div'}
                    sx={{
                      padding: '10px',
                      paddingLeft: '0px',
                    }}>
                    <Controller
                      control={control}
                      defaultValue=""
                      name="modeOfCommunication"
                      render={({ field: { onChange, value, name, ref } }) => {
                        return (
                          <Select
                            style={{ textTransform: 'capitalize' }}
                            className="common-select"
                            value={value}
                            onChange={(e: any) => {
                              onChange(e);
                            }}
                            name={name}
                            disabled={true}
                            ref={ref}
                            displayEmpty
                            inputProps={{ 'aria-label': 'Without label' }}>
                            <MenuItem value={'Phone'}>Phone</MenuItem>
                            <MenuItem value={'Email'}>Email</MenuItem>
                            <MenuItem value={'Skype'}>Skype</MenuItem>
                          </Select>
                        );
                      }}
                    />
                  </Typography>
                  {watch('modeOfCommunication') && (
                    <>
                      <Typography sx={leaveStyle.normalText}>
                        Enter{' '}
                        {watch('modeOfCommunication') === 'Phone'
                          ? 'Phone Number'
                          : watch('modeOfCommunication') === 'Email'
                          ? 'Email Id'
                          : 'Skype Id'}
                      </Typography>
                      <Typography
                        component={'div'}
                        sx={{
                          padding: '10px',
                          paddingLeft: '0px',
                        }}>
                        <Controller
                          control={control}
                          defaultValue=""
                          name="communicateVia"
                          render={({
                            field: { onChange, value, name, ref },
                          }) => {
                            return (
                              <CustomInput
                                placeHolder=""
                                value={value}
                                name={name}
                                disabled={true}
                                className={classes.custominput}
                                ref={ref}
                                onChange={(e: any) => {
                                  onChange(e);
                                }}
                                error={
                                  formState.errors?.communicateVia?.message
                                }
                              />
                            );
                          }}
                        />
                      </Typography>
                    </>
                  )}
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    sx={{
                      color: '#292929',
                      fontFamily: 'Red Hat Display Regular',
                      fontSize: '12px',
                    }}>
                    Add Comment
                  </Typography>
                  <Typography
                    component={'div'}
                    sx={{
                      padding: '0px !important',
                      paddingLeft: '0px',
                    }}>
                    <Controller
                      control={control}
                      defaultValue=""
                      name="comments"
                      render={({ field: { onChange, value, name, ref } }) => {
                        return (
                          <CustomInput
                            placeHolder="optional"
                            value={value}
                            name={name}
                            disabled={true}
                            className={classes.custominput}
                            ref={ref}
                            onChange={(e: any) => {
                              onChange(e);
                            }}
                            from={'leave'}
                            isTextArea={true}
                            error={formState.errors?.comments?.message}
                          />
                        );
                      }}
                    />
                  </Typography>
                </Grid>
              </Grid>
              <Divider />
              {watch('eSignature') && (
                <Grid container sx={{ paddingTop: '20px' }}>
                  <Grid item xs={9}>
                    <Typography
                      component={'div'}
                      className="sigCanvas"
                      sx={{
                        padding: '3px',
                        width: '350px',
                      }}>
                      <img src={watch('eSignature')} alt={'eSignature'} />
                    </Typography>
                  </Grid>
                </Grid>
              )}
            </Box>
          </form>
        </Modal>
        <Modal
          open={piBuffer.visible}
          onClose={biBufferClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description">
          <Box sx={style} className="model-widget">
            {piBuffer.status === 'denied' ? (
              <>
                <Typography id="modal-modal-title" component="h2">
                  Notes
                </Typography>
                <Typography component="div" sx={{ paddingTop: '10px' }}>
                  <TextareaAutosize
                    maxRows={4}
                    aria-label="maximum height"
                    placeholder="Reason"
                    style={{ width: '100%' }}
                    value={piBuffer?.comments}
                    disabled={userInfo.roleId !== 4}
                    onChange={(e: any) => {
                      setPiBuffer({
                        ...piBuffer,
                        comments: e?.target?.value,
                      });
                    }}
                  />
                </Typography>
                <Typography component={'div'} sx={{ paddingTop: '20px' }} />
                <Divider />
                <Typography component={'div'} sx={{ paddingTop: '20px' }} />
                <Typography className="model-btn" component={'div'}>
                  <Button variant="text" onClick={biBufferClose}>
                    Cancel
                  </Button>
                  <CustomButton
                    variant="contained"
                    isStyle={true}
                    name={'Denied'}
                    loading={payLoading}
                    onPress={() => {
                      submitPayOut();
                    }}
                  />
                </Typography>
              </>
            ) : (
              <>
                <Typography id="modal-modal-title" component="h2">
                  Payout Options
                </Typography>
                <Grid container alignItems="center" style={{ paddingTop: 20 }}>
                  <Grid item xs>
                    <Typography
                      gutterBottom
                      variant="h4"
                      component="div"
                      sx={{
                        color: '#292929',
                        fontFamily: 'Red Hat Display SemiBold',
                        fontSize: '20px',
                      }}>
                      Total Available
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      gutterBottom
                      component="div"
                      sx={{
                        color: '#DB2426',
                        fontFamily: 'Red Hat Display Bold',
                        fontSize: '20px',
                      }}>
                      {piBuffer?.item?.piBufferRequest?.availableBufferAmount
                        ? roundOfData(
                            piBuffer?.item?.piBufferRequest
                              ?.availableBufferAmount,
                          )
                        : 0}
                    </Typography>
                  </Grid>
                </Grid>
                <Divider />
                <Grid container alignItems="center" style={{ paddingTop: 20 }}>
                  <Grid sm={4}>
                    <Typography
                      gutterBottom
                      component="div"
                      sx={{
                        color: '#DB2426',
                        fontFamily: 'Red Hat Display SemiBold',
                        fontSize: '12px',
                      }}>
                      Payout Period
                    </Typography>
                  </Grid>
                  <Grid sm={5}>
                    <Typography
                      gutterBottom
                      component="div"
                      sx={{
                        color: '#DB2426',
                        fontFamily: 'Red Hat Display SemiBold',
                        fontSize: '12px',
                      }}>
                      Amount Requested
                    </Typography>
                  </Grid>
                  <Grid sm={3}>
                    <Typography
                      gutterBottom
                      component="div"
                      sx={{
                        color: '#DB2426',
                        fontFamily: 'Red Hat Display SemiBold',
                        fontSize: '12px',
                      }}>
                      Approved Amount
                    </Typography>
                  </Grid>
                </Grid>
                {piBuffer?.item?.piBufferRequest?.piBuffer &&
                  piBuffer?.item?.piBufferRequest?.piBuffer.map(
                    (item: any, index: number) => {
                      return (
                        <Grid container alignItems="center" key={index}>
                          <Grid sm={4}>
                            <Typography
                              gutterBottom
                              component="div"
                              sx={{
                                color: '#292929',
                                fontFamily: 'Red Hat Display SemiBold',
                                fontSize: '16px',
                              }}>
                              {moment(item?.payPeriod?.startDate).format(
                                'MMM DD',
                              )}{' '}
                              - {moment(item?.payPeriod?.endDate).format('DD')}
                            </Typography>
                          </Grid>
                          <Grid sm={5}>
                            <Typography
                              gutterBottom
                              component="div"
                              sx={{
                                color: '#292929',
                                fontFamily: 'Red Hat Display SemiBold',
                                fontSize: '16px',
                              }}>
                              {item?.requestedBufferAmount
                                ? roundOfData(item?.requestedBufferAmount)
                                : 0}
                            </Typography>
                          </Grid>
                          <Grid sm={3}>
                            <TextField
                              value={item?.approvedBufferAmount}
                              disabled={
                                !(
                                  userInfo.roleId === 4 &&
                                  piBuffer?.status === 'approved'
                                )
                              }
                              onChange={(e) => {
                                item.approvedBufferAmount = e?.target?.value;
                                piBuffer.item.piBufferRequest.piBuffer[index] =
                                  item;
                                piBuffer.payout =
                                  parseFloat(item?.requestedBufferAmount) ===
                                  parseFloat(e?.target?.value)
                                    ? 'approved'
                                    : 'adjusted';
                                setPiBuffer({
                                  ...piBuffer,
                                });
                              }}
                            />
                          </Grid>
                        </Grid>
                      );
                    },
                  )}
                <Grid container alignItems="center">
                  <Grid sm={12}>
                    <Typography
                      component="div"
                      sx={{
                        color: '#292929',
                        fontFamily: 'Red Hat Display Regular',
                        fontSize: '12px',
                      }}>
                      Payout Option
                    </Typography>
                    <Typography component="div" sx={{ paddingTop: '10px' }}>
                      <TextField
                        sx={{
                          '&.MuiFormControl-root': {
                            width: '100% !important',
                          },
                        }}
                        value={
                          piBuffer.payout === 'approved'
                            ? 'Approved Payout'
                            : 'Adjusted Payout'
                        }
                        disabled={true}
                      />
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container alignItems="center" sx={{ paddingTop: '10px' }}>
                  <Grid sm={12}>
                    <Typography
                      component="div"
                      sx={{
                        color: '#292929',
                        fontFamily: 'Red Hat Display Regular',
                        fontSize: '12px',
                      }}>
                      Give Reason for Payoff
                    </Typography>
                    <Typography component="div" sx={{ paddingTop: '10px' }}>
                      <TextareaAutosize
                        maxRows={4}
                        aria-label="maximum height"
                        placeholder="Reason"
                        style={{ width: '100%' }}
                        value={piBuffer?.comments}
                        disabled={
                          !(
                            userInfo.roleId === 4 &&
                            piBuffer?.status === 'approved'
                          )
                        }
                        onChange={(e: any) => {
                          setPiBuffer({
                            ...piBuffer,
                            comments: e?.target?.value,
                          });
                        }}
                      />
                    </Typography>
                  </Grid>
                </Grid>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  <Typography className="notes" component={'div'}>
                    Note: Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry
                    standard dummy text ever since the 1500s, when an unknown
                    printer took a galley of type and scrambled it to make a
                    type specimen book.
                  </Typography>
                </Typography>
                {userInfo.roleId === 4 && piBuffer?.status === 'approved' && (
                  <Typography className="model-btn" component={'div'}>
                    <Button variant="text" onClick={biBufferClose}>
                      Cancel
                    </Button>
                    <CustomButton
                      variant="contained"
                      isStyle={true}
                      name={'Submit Payout'}
                      loading={payLoading}
                      onPress={() => {
                        submitPayOut();
                      }}
                    />
                  </Typography>
                )}
              </>
            )}
          </Box>
        </Modal>
        <Modal
          open={sickHoursRequest.open}
          onClose={() =>
            setSickHoursRequest({
              note: '',
              open: false,
              rowId: '',
              status: '',
              timesheetOutSickId: '',
            })
          }
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description">
          <Box
            sx={pQuestionStyle}
            className={'confirmation-widget'}
            style={{
              border: '1px solid #e0e0e0',
              borderRadius: '10px',
              width: '50%',
            }}>
            <Typography id="modal-modal-title" component="h2">
              Notes
            </Typography>
            <Typography component="div" sx={{ paddingTop: '10px' }}>
              <TextareaAutosize
                maxRows={4}
                aria-label="maximum height"
                placeholder="Add Notes"
                style={{ width: '100%' }}
                value={sickHoursRequest.note}
                disabled={userInfo.roleId !== 4}
                onChange={(e: any) => {
                  setSickHoursRequest((prev: any) => ({
                    ...prev,
                    note: e?.target?.value,
                  }));
                }}
              />
            </Typography>
            <Typography component={'div'} sx={{ paddingTop: '20px' }} />
            <Divider />
            <Typography component={'div'} sx={{ paddingTop: '20px' }} />
            <Typography className="model-btn" component={'div'}>
              <Button
                variant="text"
                onClick={() =>
                  setSickHoursRequest({
                    note: '',
                    open: false,
                    rowId: '',
                    status: '',
                    timesheetOutSickId: '',
                  })
                }>
                Cancel
              </Button>
              <CustomButton
                variant="contained"
                isStyle={true}
                name={
                  sickHoursRequest.status === 'approved' ? 'Approve' : 'Deny'
                }
                loading={payLoading}
                onPress={() => {
                  submitSickHoursRequest();
                }}
              />
            </Typography>
          </Box>
        </Modal>
      </Typography>
    </LoadingOverlay>
  );
};

export default Queue;
